import React, { useState } from 'react'
import styled from "styled-components";
import { NavLink } from "react-router-dom";
const activeClassName = 'nav-item-active';
const SidebarNavItem = ({ active, onClick, icon, title, to = "/", select, handleShow }) => {

  const [toggleOpen, setToggleOpen] = useState(false);

  return select ? (
    <StyledSubSideNav className="navlink" active={active.toString()} to={"/"}>
      <span >{icon}</span>
      <span className="sidebar-item" onClick={() => setToggleOpen(!toggleOpen)}>{title} </span>
        {toggleOpen && <ul>
          <li className={"sub-link"} onClick={() => handleShow("general")}>general</li>
          <li className={"sub-link"} onClick={() => handleShow("email")} >email</li>
          </ul>}
     

    </StyledSubSideNav>) : (<StyledSideNav className="navlink" active={active.toString()} onClick={onClick} to={to}>
      <span >{icon}</span>
      <span className="sidebar-item">{title}</span>
    </StyledSideNav>)
}
const StyledSideNav = styled(NavLink).attrs({ activeClassName })`
  cursor: pointer;
  padding: 1.8rem 1rem 1.8rem 2.6rem;
  color: #21334f;
  background: white;
  border-radius: 4px;
  margin-bottom: 1.1rem;
  transition: padding-left 0.2s;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #34a853;
  }

  &.${activeClassName} {
    color: white;
    background: #34a853;
    transition: background 0.3s ease;
    &:hover {
      background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    }
  }

  .sidebar-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
    margin-top: -0.8rem;
  }
  .sidebar-item {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

const StyledSubSideNav = styled.div`
  cursor: pointer;
  padding: 1.8rem 1rem 1.8rem 2.6rem;
  color: #21334f;
  background: white;
  border-radius: 4px;
  margin-bottom: 1.1rem;
  transition: padding-left 0.2s;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #34a853;
  }

  &.${activeClassName} {
    color: white;
    background: #34a853;
    transition: background 0.3s ease;
    &:hover {
      background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    }
  }
 
  li{
    list-style-type: none;
    /* border-bottom: 1px solid #eee; */
    padding: .75rem 0;
  }
  li:hover{
    background: #eeeeee;
    
  }
  ul{
    /* border: 1px #eee solid; */
    width: 60%;
    margin: .5rem auto 0;
  }

  ul li{
    color: black;
    margin-left: 1rem;
    padding: 1rem;
    font-size: 1.5rem;
  }
  ul li:hover{
   text-decoration: none;
  }

  .sidebar-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
    margin-top: -0.8rem;
  }
  .sidebar-item {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    position: relative;
    overflow: hidden;
  }

`;

export default SidebarNavItem
