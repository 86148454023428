import React from "react";
import styled from "styled-components";
import ListingInterface from "../common/ListingInterface";
import { adminEngReq } from "../common/listingData";
import Layout from "../common/Layout";

const AdminEngineerRequestPage = ({ pageType }) => {

  return (
    <Layout navType="admin">
      <StyledDashboardPage >
            <ListingInterface
              details={adminEngReq}
            //   handleModal={handleModal}
              pageType={pageType}
            />
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div`
`;

export default AdminEngineerRequestPage;
