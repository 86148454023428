import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import Select from "react-select";
import FormTitleSection from "./FormTitleSection";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import { useAdmin } from "../../../context/AdminState";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import { apiPost } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { setSelect } from "../../../utils/helperFunctions";

const RequestEngForm = ({ onClick, OrgId, fetchEngReq }) => {
  const { stackList } = useAdmin();

  const initialValues = {
    numberOfEngineers: 0,
    techStackId: "",
    organisationId: OrgId ? `${OrgId}` : "",
    expectedResumptionDate: "",
    workLocation: "",
  };

  const validationSchema = Yup.object({
    numberOfEngineers: Yup.number().required("*Required"),
    techStackId: Yup.number().required("*Required"),
    expectedResumptionDate: Yup.date().required("*Required"),
    organisationId: Yup.string().required("*Required"),
    workLocation: Yup.string(),
  });

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json",
    },
  };
  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stackResult, setStackResult] = useState([]);

  useEffect(() => {
    let stckOpts = setSelect(stackList, "Stack");
    setStackResult(stckOpts);
  }, [stackList]);



  const locationOption = [
    { value: "REMOTE", label: "remote" },
    { value: "ONSITE", label: "onsite" },
  ];

  const onSubmit = (values) => {

    values.techStackId = parseInt(values.techStackId)
    values.organisationId = parseInt(values.organisationId);
    values.expectedResumptionDate = formatDate(values.expectedResumptionDate);

    requestEngineer(values);
  };


  //create organization
  const requestEngineer = async (formData) => {
    setLoading(true);

    //make call to the api
    try {
      let response = await apiPost(
        `/assignments/requests`,
        formData,
        multipartConfig,
      )
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
        setSuccess(true);
        setLoading(false);

    } catch (err) {
      console.log({err});
      if (err) {
        toast.error(err || "Not able to request engineer at this time");
      }
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    fetchEngReq();
    // eslint-disable-next-line
  }, [success]);

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
  };
  const customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "rgba(33,51,79,0.8)",
        fontSize: "1rem",
        fontWeight: "700",
      };
    },

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#4285F4" : "#fff",
        color: isFocused ? "#fff" : "#263238",
        fontSize: "1.3rem",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Request Engineers" onClick={() => closeForm()} />
      <div className="form-section">
        {!success && (
          <form onSubmit={formik.handleSubmit} className="client-form">
            <FormInputComponent
              label="Number Of Engineers"
              type="number"
              name="numberOfEngineers"
              placeholder="Enter Number of Engineers"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.numberOfEngineers}
              error={
                formik.touched.numberOfEngineers &&
                  formik.errors.numberOfEngineers
                  ? formik.errors.numberOfEngineers
                  : null
              }
            />

            <FormSelectComponent
              label="Tech stack (select technology)"
              name="techStackId"
              options={stackResult}
              onChange={formik.handleChange}
              value={formik.values.techStackId}
              error={
                formik.touched.techStackId && formik.errors.techStackId
                  ? formik.errors.techStackId
                  : null
              }
            />

            <label className="assig-label" htmlFor="work-loacation">
              Work Location
            </label>
            <Select
              className="assig-select"
              options={locationOption}
              styles={customStyles}
              placeholder="Select Work Location"
              name="workLocation"
              onChange={(selectedOption) => {
                let d = selectedOption;
                let even = { target: { name: "workLocation", value: d.value } };
                formik.handleChange(even);
              }}
              onBlur={formik.handleBlur}
              type="select"
              value={{
                label: formik.values.workLocation,
                value: formik.values.workLocation,
              }}
              error={
                formik.touched.workLocation && formik.errors.workLocation
                  ? formik.errors.workLocation
                  : null
              }
            />

            <FormInputComponent
              label="Resumption Date"
              type="date"
              name="expectedResumptionDate"
              placeholder="Enter resumption date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.expectedResumptionDate}
              error={
                formik.touched.expectedResumptionDate &&
                  formik.errors.expectedResumptionDate
                  ? formik.errors.expectedResumptionDate
                  : null
              }
              className="assig-date"
            />

            <MyButton className="form-btn" type={"submit"} full>
              {" "}
              {loading ? (
                <ClipLoader color={"#fff"} size={"30px"} />
              ) : (
                  "Request Engineers"
                )}
            </MyButton>
          </form>
        )}
        {success && (
          <div className="form-success card">
            <img
              src="/images/sucess-icon.svg"
              alt="green-tick"
              className="sucess-icon"
            />
            <h2 className="form-font green-text">Success</h2>
            <p className="form-font success-msg">
              You've successfully Requested for Engineers.
            </p>
            <MyButton className="form-btn success-btn" onClick={closeForm}>
              View Request List
            </MyButton>
          </div>
        )}
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-btn {
    width: 100%;
    margin-top: 3.2rem;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  input .assig-date {
    width: 100% !important;
  }
  .css-yk16xz-control {
    border-radius: 0;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    margin: 0.5rem 0 2.4rem;
    height: 48px;
    font-size: 16px;
    color: green;
  }
  .css-1pahdxg-control {
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    box-shadow: none;
    height: 48px;
    margin: 0.5rem 0 2.4rem;
  }
  .css-1pahdxg-control:hover {
    border: 1px solid rgba(33, 51, 79, 0.15);
    border-radius: 0;
    /* box-shadow: none; */
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    padding-right: 16px;
  }
  .css-tj5bde-Svg {
    color: #21334f;
    width: 15px;
  }
  .select-work {
    color: rgba(33, 51, 79, 0.8);
    font-size: 16px;
    font-weight: 400;
    /* line-height: 20px; */
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
  }
  .assig-label {
    color: black;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 20px; */
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0rem 1rem 1.7rem 1rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
    margin: 0 auto;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .assig-select::placeholder {
    color: rgba(33, 51, 79, 0.8) !important;
  }
`;

export default RequestEngForm;
