import React, { useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";



const FormUploadComponent = ({
  label,
  error,
  setSelectedFile,
  selectedFile,
  pictureType,
  docType,
  acceptTypes,
  receiptType,
  pdf
}) => {
  const [imageUrl, setImageUrl] = useState("");


  const handleFile = (file) => {
    setSelectedFile(file);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const deleteFile = () => {
    setSelectedFile({});
    setImageUrl("");
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFile(files[0]);
    }
  };
  const fileInputRef = useRef();

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const setPreview = () => {
    const file = fileInputRef.current.files[0]
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    }

    if (file) {
      reader.readAsDataURL(file);
      setImageUrl(reader.result);
    }
    else {
      setImageUrl("");
    }
  }


  const checkType = (selectedFile) => {
    if (selectedFile.name) {
      const value = selectedFile.name.split(".");
      const fileType = value[value.length - 1];

      if (pictureType && pictureType.length > 0 && 
        (fileType === "jpg" ||
        fileType === "jpeg" ||
        fileType === "gif" ||
        fileType === "png")
      ) {
        return pictureType.find((x) => x === fileType);
      } else if (
        docType &&
        docType.length > 0 &&
        (fileType === "pdf" || fileType === "docx" || fileType === "doc")
      ) {
        return docType.find((x) => x === fileType);
      } else if (
        receiptType &&
        receiptType.length > 0 &&
        (fileType === "png" ||
          fileType === "jpg" ||
          fileType === "jpeg" ||
          fileType === "gif" ||
          fileType === "pdf")
      ) {
        return receiptType.find((x) => x === fileType);
      } else {
        return undefined;
      }
    }

  };

  const fileSelected = () => {
    if (fileInputRef.current.files.length) {
      const approvedFileType = checkType(fileInputRef.current.files[0]);
 
      if (
        approvedFileType !== undefined
      ) {
        if ((fileInputRef.current.files[0].size / 1024) / 1024 <= 5) {
          handleFile(fileInputRef.current.files[0]);
          setPreview();
        }
        else {
          toast.error("File size is greater than 5mb");
        }

      } else {
        toast.error("File type is not accepted");
      }
    } else {
      toast.error("No file selected");
    }
  };

  const handleTouch = (e) => { };

  return (
    <StyledFormUploadComponent>
      <div
        className="drop-container"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        onBlur={handleTouch}
      >
        <div className="form-font drop-message">
          {imageUrl ? <img src={imageUrl} className="img-preview" alt="preview" /> : <> <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={fileSelected}
          />

            <div className="upload-icon">

            </div>
            <p className="form-label">{label}</p>
            Drag and drop file here or{" "}
            <span className="underline">select them from your computer</span>
            <span className="no-underline">{acceptTypes}</span>

          </>}

        </div>
      </div>
      {selectedFile.name && (
        <div className="flex file-status-bar">
          <div className="flex file-display">
            <div className="file-type-logo"></div>
            <span className="form-label file-name">{selectedFile.name}</span>
          </div>
          <div
            className=" form-label file-name file-remove "
            onClick={deleteFile}
          >
            x
          </div>
        </div>
      )}
      {error && <div className="form-error file-error-message">{error}</div>}
    </StyledFormUploadComponent>
  );
};

const StyledFormUploadComponent = styled.div`
    
        margin-bottom: 3.2rem;
    .drop-container{
        padding-top: 1rem;
        width: 100%;
        height: 13.8rem;
        background: #FFFFFF;
        border: 1px dashed rgba(33, 51, 79, 0.1);
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 1.469rem;
    }
    .upload-icon{
        width: 2.685rem;
        height: 2.685rem;
        background: url(images/upload-icon.svg) no-repeat center center; 
        background-size: 100%;
        text-align: center;
        margin: 0 auto;
        padding-top: 30px;
    }
    .underline{
        text-decoration: underline;
        display: block;
    }
    .underline:hover{
        text-decoration: none;
    }
    .no-underline{
      display: block;
      margin-top: 1rem;
    }
    .drop-message{
        margin: 0 auto;
        font-weight: normal;
        width: 25.25rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: center;
    }
    .img-preview{
      width: 40%;
      border: 1px dashed rgba(33,51,79,0.1);
    }
    .flex{
        display: flex;
        flex-direction: row;
    }
    .file-status-bar{
        justify-content: space-between;
    }
    .file-display{
        align-items: center;
    }
    .file-type-logo{
        display: inline-block;
        width: 4rem;
        height: 4rem;
        background: url(images/file-icon.svg) no-repeat center center; 
        background-size: 100%;
    }
    .file-name{
        margin-top: 1rem;
        margin-left: .9rem;
        display: inline;
        max-width: 250px;
    }
    .file-error-message{
        display: inline;
    }
    .file-remove{
        cursor: pointer;
        font-size: 1.6rem;
    }
    .file-input {
        display: none;
    } 
    @media only screen and (max-width: 1200px) {
        .drop-container{
            width: 100%;
        }
    }
`;

export default FormUploadComponent;
