import React from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import {apiGet} from "../../../../utils/apiHelpers";
import fileDownload from "../../../../utils/file-download";



function InvoiceTableData({startDate}) {
  const {id} = useParams();

  const downloadSubscriptionContract = async () => {
    try {
      const data = await apiGet(`/download/subscriptionContract/${id}`, {responseType: 'blob'}, "client");

      fileDownload(data.data, 'Contract.pdf')
   
    } catch (err) {
      console.error(err);
    }
  };

  const downloadSubscriptionReceipt = async () => {
    try {
      const data = await apiGet(`/download/subscriptionReceipt/${id}`, {responseType: 'blob'}, "client");
      fileDownload(data.data, 'Receipt.pdf')
   
    } catch (err) {
      console.error(err);
    }
  };

    return (
      <StyledInvoce>
        <div className="invoice-display ">
          <div className="grid-container mt-5">
            <div>
              <span>
                <span>
                  <img
                    className="pdf-icon"
                    src="/images/pdf-icon.svg"
                    alt="pdf-icon"
                  />
                </span>
              </span>
              <span className="ml-5 table-content">Subscription Receipt.pdf</span>
            </div>
            <div className="mt-3 text-center">
              <span className="invoice-date">Date of invoice:</span>
              <span className="ml-3 table-content">{startDate}</span>
            </div>
            <div className="invoice-download download-wrapper" onClick={downloadSubscriptionReceipt}>
              <div style={{ float: "right" }}>
                <span>
                  <img
                    className="dark-download-icon"
                    src="/images/dark-download-icon.svg"
                    onMouseOver={e => (e.currentTarget.src ='/images/download-icon.svg')}
                    onMouseOut={e => (e.currentTarget.src = '/images/dark-download-icon.svg')} 
                    alt="download-icon-dark"
                  />
                </span>
                <span className="ml-3 table-content">Download</span>
              </div>
            </div>
          </div>

          <div className="grid-container mt-5">
            <div >
              <span>
                <span>
                  <img
                    className="pdf-icon"
                    src="/images/pdf-icon.svg"
                    alt="pdf-icon"
                  />
                </span>
              </span>
              <span className="ml-5 table-content">Subscription Contract.pdf</span>
            </div>
            <div className="mt-3 text-center">
              <span className="invoice-date">Date of invoice:</span>
              <span className="ml-3 table-content">{startDate}</span>
            </div>
            <div className="invoice-download" onClick={downloadSubscriptionContract}>
              <div style={{ float: "right" }} >
                <span style={{ cursor: "pointer" }}>
                  <img
                    className="dark-download-icon"
                    src="/images/dark-download-icon.svg"
                    onMouseOver={e => (e.currentTarget.src ='/images/download-icon.svg')}
                    onMouseOut={e => (e.currentTarget.src = '/images/dark-download-icon.svg')}
                    alt="download-icon-dark"
                  />
                </span>
                <span className="ml-3 table-content">Download</span>
              </div>
            </div>
          </div>
        </div>
      </StyledInvoce>
    );
}

const StyledInvoce = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: #fff;
    padding: 2rem 2.5rem;
  }
  .pdf-icon {
    height: 5rem;
    width: 5rem;
  }
  .dark-download-icon {
    height: 5rem;
    width: 2rem;
  }
  .table-content {
    color: rgba(33, 51, 79, 1);
    font-size: 1.5rem;
    font-weight: 400;
  }
  .invoice-date {
    font-weight: 300;
    font-size: 1.6rem;
    color: rgba(33, 51, 79, 1);
  }
  .invoice-download {
    cursor: pointer;
  }
  .invoice-download:hover {
    span{
      color: green;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
  .grid-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  @media screen and (max-width: 1170px) {
    width: 100%;
  }
`;

export default InvoiceTableData