import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { apiPut, apiGet } from "../../../utils/apiHelpers";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import { options } from "./formSelectOptions";
import { getCountryList } from "../../../utils/helperFunctions";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

const phoneRegExp = /^\d*(\+\d+)?$/;


const validationSchema = Yup.object({
  code: Yup.string().required("*Required"),
  name: Yup.string().required("*Required"),
  contactPerson: Yup.string().required("*Required"),
  email: Yup.string().email("Invalid email format").required("*Required"),
  telephoneNumber: Yup.string()
    .min(9, "*Phone number is not valid")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  address: Yup.string().required("*Required"),
  countryId: Yup.number().required("*Required"),
  industryId: Yup.number().required("*Required"),
  engineeringLead: Yup.bool().required("*Required"),
  numberOfEmployees: Yup.string().required("*Required"),
  numberOfDevelopers: Yup.string().required("*Required"),
  status: Yup.string().required("*Required"),
  hearAboutUs: Yup.string().required("*Required"),

})

const engineerOptions = [
  { value: "", label: "Select option" },
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];


const UpdateClientForm = ({ onClick, orgId, reload }) => {

  const [success, setSuccess] = useState(false);
  const [logoDoc, setLogoDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});
  const [logoDocError, setLogoDocError] = useState("");
  const [industries, setIndustries] = useState([]);

  const [initialValues, setInitialValues] = useState({
    code: "",
    name: "",
    contactPerson: "",
    email: "",
    telephoneNumber: 0,
    address: "",
    countryId: 0,
    industryId: 0,
    engineeringLead: true,
    numberOfEmployees: "",
    numberOfDevelopers: "",
    status: "",
    hearAboutUs: "",
    packageId: 0
  });


  useEffect(() => {
    setLogoDocError("");
  }, [logoDoc]);


  useEffect(() => {
    fetchOrganisationDetails();
    fetchIndustries();
    getCountryList().then((res) => setCountryList(res));
    // eslint-disable-next-line
  }, []);


  // Fetch industries Details
  const fetchIndustries = async () => {
    try {
      const res = await apiGet(`/admin/industries`);
      let result = res.data.data;
      for (let i = 0; i < result.length; i++) {
        let item = result[i];
        Object.assign(item, { label: item.name, value: item.id })
      }

      setIndustries(result);
    } catch (err) {
      toast.error(err);
    }
  };


  // Fetch engineer Details
  const fetchOrganisationDetails = async () => {
    try {
      const res = await apiGet(`/admin/organisations/${orgId}`);
      let result = res.data.data;

      let id = result && result.subscription && result.subscription.packageId;

      Object.assign(result, { packageId: id });
      const { subscription, ...rest } = result;

      setOrgDetails(rest);
      setInitialValues(rest)

    } catch (err) {
      toast.error(err)
    }
  };


  // Edit engineer Details
  const editOrganisationDetails = async (data) => {
    setLoading(true);
    try {
      
      let response = await apiPut(`/admin/organisations/${orgId}`, data, {});
      if (response.error) {
        throw response.data.response.status === 500 ? new Error("") : response.data;
      }
      setSuccess(true);
      setLoading(false);
      reload();

    } catch (err) {
      setLoading(false);
      toast.error(
        err?.response?.data?.message ||
        "Not able to edit engineer at this time"
      );
    }
  };

  const onSubmit = (allValues) => {

    const { subscription, id, logo, packageId, ...values } = allValues;

    let formData = new FormData();

    if (logoDoc.name) {
      formData.append("logo_upload", logoDoc);
    }

    formData.append(
      "organisation_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );
    editOrganisationDetails(formData);
  };


  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setLogoDoc(() => ({}));
    setSuccess(false);
  };


  useEffect(() => {
    formik.setValues(orgDetails)
    // eslint-disable-next-line
  }, [orgDetails, initialValues]);

  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title="Edit Organization" onClick={onClick} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <div className="update-upload">
            <FormInputComponent
              label="Code"
              type="text"
              name="code"
              placeholder="Enter code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              error={
                formik.touched.code && formik.errors.code
                  ? formik.errors.code
                  : null
              }
            />

            <FormInputComponent
              label="Contact Person’s Name"
              type="text"
              name="contactPerson"
              placeholder="Enter company person’s name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactPerson}
              error={
                formik.touched.contactPerson && formik.errors.contactPerson
                  ? formik.errors.contactPerson
                  : null
              }
            />

            <FormInputComponent
              label="Email address"
              type="email"
              name="email"
              placeholder="Enter email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
            <FormInputComponent
              label="Phone number"
              type="tel"
              name="telephoneNumber"
              placeholder="Enter phone number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telephoneNumber}
              error={
                formik.touched.telephoneNumber && formik.errors.telephoneNumber
                  ? formik.errors.telephoneNumber
                  : null
              }
            />
            <FormInputComponent
              label="Address"
              type="text"
              name="address"
              placeholder="Enter address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              error={
                formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : null
              }
            />

            <FormInputComponent
              label="Company Name"
              type="text"
              name="name"
              placeholder="Enter company name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
            />

            <FormSelectComponent
              label="Country Name"
              name="countryId"
              options={countryList}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.countryId}
              error={
                formik.touched.countryId && formik.errors.countryId
                  ? formik.errors.countryId
                  : null
              }
            />


            <FormSelectComponent
              label="Industry Type"
              name="industryId"
              options={industries}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.industryId}
              error={
                formik.touched.industryId && formik.errors.industryId
                  ? formik.errors.industryId
                  : null
              }
            />

            <FormSelectComponent
              label="Engineering Lead"
              name="engineeringLead"
              options={engineerOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.engineeringLead}
              error={
                formik.touched.engineeringLead && formik.errors.engineeringLead
                  ? formik.errors.engineeringLead
                  : null
              }
            />
            <FormInputComponent
              label="Number Of Employees"
              type="text"
              name="numberOfEmployees"
              placeholder="Enter number of employees"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.numberOfEmployees}
              error={
                formik.touched.numberOfEmployees &&
                  formik.errors.numberOfEmployees
                  ? formik.errors.numberOfEmployees
                  : null
              }
            />
            <FormInputComponent
              label="Number Of Developers"
              type="text"
              name="numberOfDevelopers"
              placeholder="Enter number of developers"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.numberOfDevelopers}
              error={
                formik.touched.numberOfDevelopers &&
                  formik.errors.numberOfDevelopers
                  ? formik.errors.numberOfDevelopers
                  : null
              }
            />


            <FormSelectComponent
              label="Status"
              name="status"
              options={options}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status}
              error={
                formik.touched.status && formik.errors.status
                  ? formik.errors.status
                  : null
              }
            />

            <FormInputComponent
              label="Where Did You Hear About Us"
              type="text"
              name="hearAboutUs"
              placeholder="Enter details"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.hearAboutUs}
              error={
                formik.touched.hearAboutUs && formik.errors.hearAboutUs
                  ? formik.errors.hearAboutUs
                  : null
              }
            />

            <FormUploadComponent
              label="Upload Logo"
              error={logoDocError}
              selectedFile={logoDoc}
              setSelectedFile={setLogoDoc}
            />
          </div>
          <MyButton
            className="form-btn add-client-btn"
            type="submit"
            full
          >
            {loading ? (<ClipLoader color={"#fff"} size={"30px"} />
            ) : ("Update Organization")}
          </MyButton>

        </form>
        <div className="form-success">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully Updated Organization.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go to Organization Page
          </MyButton>
        </div>
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
 
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .update-upload{
      margin-bottom: 6rem;
  }
 
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default UpdateClientForm;
