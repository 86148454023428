import {
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_FAIL,
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOADING,
  VERIFIED,
} from "./types";

export default function authReducer(state, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      if (action.payload.type === "admin") {
        localStorage.setItem("token", action.payload.data.token);
        localStorage.setItem("refreshToken", action.payload.data.refreshToken);
        localStorage.setItem(
          "client_details",
          JSON.stringify(action.payload.data.user)
        );
        localStorage.setItem("expiry", action.payload.expiry);
        window.location.href = `/admin/overview`;
      } else if (action.payload.type === "client") {
        localStorage.setItem("token", action.payload.data.token);
        localStorage.setItem("refreshToken", action.payload.data.refreshToken);
        localStorage.setItem(
          "client_details",
          JSON.stringify(action.payload.data.user)
        );
        localStorage.setItem("expiry", action.payload.expiry);
        window.location.href = `/client/dashboard`;
      }

      return {
        ...state,
        ...action.payload,
        client_details: action.payload.data.user,
        isAuthenticated: action.payload.isAuthenticated,
        loading: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      if (action.payload === "admin") {
        localStorage.removeItem("token");
        localStorage.removeItem("client_details");

        window.location.href = `/login`;
      } else if (action.payload === "client") {
        localStorage.removeItem("token");
        localStorage.removeItem("client_details");

        window.location.href = `/login`;
      }
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case VERIFIED:
      return {
        ...state,
        isVerified: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
