import React from 'react';
import styled from "styled-components"


import ClipLoader from "react-spinners/ClipLoader"
const MyButton = ({children, white, href, min, max, full, className, type, green, onClick, loading}) => {

    return (
        <div className="btn-parent"> 
            {type === 'submit' ? <StyledButton className={className || "btn btn-component"} white={white} green={green} min={min} full={full} type={type} max={max} href={href} target="_blank" onClick={onClick}>
            {children}
            <div className="spin-parent">
              {loading && <ClipLoader color="white" size="40px" className="spinner" />}
            </div>
            </StyledButton> : 
            <AnchorButton className={className || "btn btn-component"} white={white} min={min} type={type} green={green} max={max} full={full} href={href} target="_blank" onClick={onClick}>
            {children}
            <div className="spin-parent">
              {loading && <ClipLoader color="white" size="40px" className="spinner" />}
            </div>
            </AnchorButton>}
        </div>
    )
}
const StyledButton = styled.button`
    color: white;
    text-decoration: none;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    width: 18.1rem;
    width: ${({min, max, full}) => min ? '26.1rem' : max ? '40rem' : full ? '100%' : '18.1rem'};
    height: 5.2rem;
    font-weight: 400;
    font-size: 16px;
    border: none;
    text-align: center;
    color: ${({white}) => white ? "#34A853": "white"};
    background: linear-gradient(96.67deg, #34A853 0%, #B8D344 100%);
    background: ${({white, green}) => white ? '#FFF': green ? "#34A853" :'linear-gradient(96.67deg, #34A853 0%, #B8D344 100%)'};
    white-space: nowrap;
    :hover{
        cursor: pointer;
    }
  
    .spin-parent{
    position: absolute;
  }
  
    @media only screen and (max-width: 600px) {
        font-size:12px;
        width: 15rem;
        height: 4.2rem;

    }
    @media only screen and (max-width: 400px) {
        font-size: 12px;
        width: 11rem;
        height: 4rem;

    }

`;
const AnchorButton = styled.a`
    color: white;
    text-decoration: none;
    letter-spacing: 1px;
    display: flex;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    width: 18.1rem;
    width: ${({min, max, full}) => min ? '26.1rem' : max ? '40rem' : full? '100%': '18.1rem'};
    height: 5.2rem;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    border: none;
    color: ${({white}) => white ? "#34A853": "white"};
    background: linear-gradient(96.67deg, #34A853 0%, #B8D344 100%);
    background: ${({white, green}) => white ? '#FFF': green ? "#34A853" :'linear-gradient(96.67deg, #34A853 0%, #B8D344 100%)'};
    white-space: nowrap;
    :hover{
        cursor: pointer;
    }
    .spin-parent{
    position: absolute;
  }

    .btn-component{
        background: yellow !important;

    }

    @media only screen and (max-width: 600px) {
        font-size:12px;
        width: 12rem;
        height: 4.2rem;

    }
    @media only screen and (max-width: 400px) {
        font-size: 13px;
        width: 12rem;
        height: 4rem;

    }


`;

export default MyButton
