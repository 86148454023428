import React from "react";
import styled from "styled-components";
import ListingInterface from "../common/ListingInterface";
import { adminSubReq } from "../common/listingData";
import Layout from "../common/Layout";

const AdminSubscriptionRequestPage = ({ pageType }) => {

  return (
    <Layout navType="admin">
      <StyledDashboardPage >
            <ListingInterface
              details={adminSubReq}
              pageType={pageType}
            />
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div`
`;

export default AdminSubscriptionRequestPage;
