import React from 'react'
import styled from "styled-components";

const SelectedStack = ({name, onClick}) => {
    return (
        <StyledSelectedStack>
            <p className="title">{name}</p>
            <p className="delete" onClick={onClick}>x</p>
        </StyledSelectedStack>
    )
}

const StyledSelectedStack = styled.div`
    margin-bottom: 2.4rem;
    margin-right: .9rem;
    display: flex;
    padding: 1rem;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    min-width: 8.8rem;
    min-height: 3rem;
    background: rgba(33, 51, 79, 0.1);
    color: #21334f;
    font-size: 1.4rem;
    .title{
        margin-right: 1.8rem
    }
    .delete{
        cursor: pointer;
    }
    p{
        margin-bottom: 0;
    }
    
`;

export default SelectedStack
