import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from '../../landingPage/MyButton';
import FormInputComponent from './enterpriseFormInputs/FormInputComponent';
import FormTitleSection from './FormTitleSection';
import { toast } from "react-toastify";
import { apiPut } from "../../../utils/apiHelpers";

const initialValues = {
    name: "",

};
 
const validationSchema = Yup.object({
    name: Yup.string().required('*Required'),

})


const UpdateIndustry = ({ onClick, industryId, handleIndustry }) => {

    const [success, setSuccess] = useState(false);


    const onSubmit = (values) => {
        updateIndustry(values);
    };

    //create Engineer
    const updateIndustry = async (values) => {
        //make call to the api
        try {
            await apiPut(`/admin/industries/${industryId}`, values, {});

            setSuccess(true);

        } catch (err) {
            toast.error(
                err?.response?.data?.message ||
                "Not able to update industry at this time"
            );
        }
    };


    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const closeForm = () => {
        formik.resetForm()
        onClick();
        setSuccess(false);
    }


    return (
        <StyledCreatePackageForm success={success}>
            <FormTitleSection title="Edit Industry" onClick={handleIndustry} />
            <div className="form-section">
                <form onSubmit={formik.handleSubmit} className="client-form">
                    <p className="form-font form-title">Industry Details</p>



                    <FormInputComponent
                        label="Industry Name"
                        type="text"
                        name="name"
                        placeholder="Enter industry name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        error={
                            formik.touched.name && formik.errors.name
                                ? formik.errors.name
                                : null
                        }
                    />

                    <MyButton
                        className="form-btn add-client-btn"
                        loading={false}
                        type={"submit"}
                        full
                    >
                        {(!false && "Edit Industry") || ""}
                    </MyButton>
                </form>
                <div className="form-success">
                    <img
                        src="/images/sucess-icon.svg"
                        alt="green-tick"
                        className="sucess-icon"
                    />
                    <h2 className="form-font green-text">Success</h2>
                    <p className="form-font success-msg">
                        You successfully edited an Industry.
        </p>
                    <MyButton className="form-btn success-btn" onClick={closeForm}>
                        Okay, Go back to Industry Listing Page
        </MyButton>
                </div>
            </div>
        </StyledCreatePackageForm>
    )
}

const StyledCreatePackageForm = styled.div`
    width: 100%;
    height: 100vh;
    z-index: 10;
    overflow-y: scroll;
    background: white;
    border: 1px solid rgba(33, 51, 79, 0.15);
    .form-section{
        padding: 4rem 4rem 0 4rem;
        overflow-y: scroll;
    }
    .form-title{
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 3.2rem;
    }
    .add-client-btn{
        margin-bottom: 23.8rem;
    }
    .client-form{
        display: ${({ success }) => (success ? "none" : "block")};
        
    }
    .form-success{
        display: ${({ success }) => (success ? "flex" : "none")};
        width: 100%;
        flex-direction: column;
        align-items: center;

    }
    .sucess-icon{
        width: 4.2rem;
        margin-bottom: 1.7rem;
    }
    .green-text{
        font-size: 2.4rem;
        margin-bottom: 2.2.rem;
    }
    .success-msg{
        width: 25.9rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
        margin-bottom: 3.8rem;
    }
    .success-btn{
        width: 100% !important;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 3.2rem;
    }
    .success-btn:hover,
    .success-btn:focus{
        background: #34A853;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
    }

`;

export default UpdateIndustry
