import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { apiPut, apiPatch } from "../../../utils/apiHelpers";
import useComponentVisible from "../../../utils/useComponentVisible";
import { Link } from "react-router-dom";
import dateFormat from "../../../utils/FormatDate";
import { useAuth } from "../../../context/AuthState";

const { TableDateFormat } = dateFormat;

const ClientBar = ({
  item,
  tableDetails,
  pageType,
  handleSubModal,
  routeId,
  handleEngModal,
  fetchClients,
  refId,
  handleClientModal,
  handleEditSubModal,
  handlePackageModal,
  handleEditAssignment,
  handleIndustry,
  handleTechStack,
  viewUser,
  deleteUsers,
  deactivateUser,
  activateUser,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const { permissionCheck, } = useAuth();
  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles.split(",")

  let id = item.id;
  const { assignmentId } = item;
  let status = item.status || "";
  let organisation = item.organisation;

  const history = useHistory();
  const currentLoc = history.location.pathname;

  const activateClient = async (id) => {
    const [firstName, lastName] = item.contactPerson.split(" ");
    const userPayload = {
      email: item.email.toLowerCase(),
      firstName: firstName,
      lastName: lastName,
      organisationRole: "user",
      phone: item.telephoneNumber,
      roles: [0],
      status: "ACTIVE",
    };

    await apiPut(`/admin/organisations/${id}/activate`, userPayload, {});
    window.location.reload();
  };

  const deactivateClient = async (id) => {
    const [firstName, lastName] = item.contactPerson.split(" ");
    const userPayload = {
      email: item.email.toLowerCase(),
      firstName: firstName,
      lastName: lastName,
      organisationRole: "user",
      phone: item.telephoneNumber,
      roles: [0],
      status: "INACTIVE",
    };

    await apiPut(`/admin/organisations/${id}/deactivate`, userPayload, {});

    window.location.reload();
  };

  const handleCancelSubRequest = async () => {
    const userPayload = { ...item, status: "CANCELLED" }

    await apiPatch(`/subscriptions/requests/${id}`, userPayload, {});

    window.location.reload();
  }

  const handleCancelEngRequest = async () => {
    const userPayload = { ...item, status: "CANCELLED" };
    await apiPatch(`/assignments/requests/${id}`, userPayload, {});
    window.location.reload();
  };

  return (
    <StyledClientBar status={status.toLowerCase()}>
      {tableDetails.map((detail, index) => {
        let current = item[detail];

        if (detail === "used") {
          current = "used";
        }
        return current === status ? (
          <td key={index}>
            <button className="client-btn">{status || "Unknown"}</button>
          </td>
        ) : current === "used" ? (
          <td key={index}>{item.numEngineers - item.availableCredit}</td>
        ) : detail === "dateAdded" ? (
          <td key={index}>{TableDateFormat(current)}</td>
        ) : detail === "action" ? (
          <td className="dots" key={index}>
            <span
              onMouseOver={() => setIsComponentVisible(!isComponentVisible)}
            >
              <span className="ellipse"></span>
              <span className="ellipse"></span>
              <span className="ellipse"></span>
            </span>

            {isComponentVisible &&
              (pageType === "clients" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, [
                    "manageClient", "manageAdminUser"
                  ]) && (
                      <p
                        onClick={(e) =>
                          history.push(`/admin/dashboard/users/${id}`)
                        }
                      >

                        List Users
                      </p>
                    )}
                  {permissionCheck(permArr, [
                    "assignEngineer",
                    "manageClient",
                    "manageEngineer",
                    "manageOrganisationUser",
                    "viewAssignment",
                    "uploadEngineerReport",
                    "viewEngineerReport",
                    "addEngineerComment",
                  ]) && (
                      <p onClick={() => history.push(`/admin/dashboard/${id}`)}>
                        View Org Info
                      </p>
                    )}

                  {permissionCheck(permArr, [
                    "manageSubscription", "viewSubscription"
                  ]) && (

                      <p
                        onClick={() =>
                          history.push(`/admin/subscriptions/sublisting/${id}`, {
                            organisation, orgId: item.id
                          })
                        }
                      >
                        View Subscription
                      </p>
                    )}
                  <p onClick={() => handleClientModal(id)}>Update Client</p>

                  <p onClick={() => activateClient(id)}>Activate Client</p>
                  <p onClick={() => deactivateClient(id)} className="red">
                    Deactivate Client
                  </p>

                </div>
              ) : pageType === "overview" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, [
                    "manageClient", "manageAdminUser"
                  ]) && (
                      <p
                        onClick={(e) =>
                          history.push(`/admin/dashboard/users/${id}`)
                        }
                      >
                        List Users
                      </p>
                    )}
                  {permissionCheck(permArr, [
                    "assignEngineer",
                    "manageClient",
                    "manageEngineer",
                    "manageOrganisationUser",
                    "viewAssignment",
                    "uploadEngineerReport",
                    "viewEngineerReport",
                    "addEngineerComment",
                  ]) && (
                      <p onClick={() => history.push(`/admin/dashboard/${id}`)}>
                        View Org Info
                      </p>
                    )}

                  {permissionCheck(permArr, [
                    "manageSubscription", "viewSubscription"
                  ]) && (
                      <p
                        onClick={() =>
                          history.push(`/admin/subscriptions/sublisting/${id}`, {
                            organisation,
                          })
                        }
                      >
                        View Subscription
                      </p>
                    )}

                  <p onClick={() => activateClient(id)}>Activate Client</p>
                  <p onClick={() => deactivateClient(id)} className="red">
                    Deactivate Client
                  </p>
                </div>
              ) : pageType === "subscriptions" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, [
                    "manageSubscription", "viewSubscription"
                  ]) && (
                      <p>
                        <Link
                          to={{
                            pathname: `${currentLoc}/${id}`,
                                  state: [
                                    { routeId },
                                    { refId },
                                    { subId: item.id }
                                  ],
                          }}
                        >
                          View Subscription
                    </Link>
                      </p>
                    )}
                </div>
              ) : pageType === "engineers" ? (
                <div ref={ref} className="popup">
                  <p onClick={() => handleEngModal(id)}>View/Edit Engineer</p>
                  {permissionCheck(permArr, [
                    "viewAssignment",
                    "viewEngineerReport",
                    "uploadEngineerReport",
                    "uploadEngineerReport",
                    "addEngineerComment",
                  ]) && (
                      <p>
                        <Link
                          to={{
                            pathname: `/admin/engineers/assignments/${id}`,
                            state: {
                              routeId,
                            },
                          }}
                        >
                          View Assignment
                    </Link>
                      </p>
                    )}
                </div>
              ) : pageType === "sub" ? (
                <div ref={ref} className="popup">
                  <p>
                    <Link
                      to={{
                        pathname: `${currentLoc}/${id}`,
                        state: [
                          {routeId},
                          {refId},
                          {subId: item.id}
                        ],
                      }}
                    >
                      View Subscription
                    </Link>
                  </p>
                  <p onClick={() => handleEditSubModal(id)}>
                    Update Subscription
                  </p>
                </div>
              ) : pageType === "users" ? (
                <div ref={ref} className="popup">
                  <p onClick={() => viewUser(item)}>View User info</p>
                  <p onClick={() => activateUser(item)}>Activate User</p>
                  <p onClick={() => deactivateUser(item)} className="red">
                    Deactivate User
                  </p>
                  <p onClick={() => deleteUsers(item)} className="red">
                    Delete User
                  </p>
                </div>
              ) : pageType === "package" ? (
                <div ref={ref} className="popup">
                  <p onClick={() => handlePackageModal(id)}>View Package</p>

                  <p className="red">Delete Package</p>
                </div>
              ) : pageType === "squad" ? (
                                  <div ref={ref} className="techpop">
                  <p onClick={() => viewUser(item)}>View Squad</p>
                </div>
              ) : pageType === "industry" ? (
                <div ref={ref} className="techpop">
                  <p onClick={() => handleIndustry(id)}>Update Industry</p>
                </div>
              ) : pageType === "techstack" ? (
                <div ref={ref} className="techpop">
                  <p onClick={() => handleTechStack(id)}>Update TechStack</p>
                </div>
              ) : pageType === "client_subscriptions" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, [
                    "viewSubscription"
                  ]) && (
                      <p>
                        <Link
                          to={{
                            pathname: `/client/dashboard/${id}`,
                            state: {
                              routeId,
                            },
                          }}
                        >
                          View Subscription
                    </Link>
                      </p>
                    )}
                </div>
              ) : pageType === "client_engineers" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, [
                    "uploadEngineerReport",
                    "uploadEngineerReport",
                    "viewEngineerReport",
                    "addEngineerComment",
                  ]) && (
                      <p
                        onClick={() =>
                          history.push({
                            pathname: `/client/engineers/${id}`,
                          })
                        }
                      >
                        View Assignment
                      </p>
                    )}
                </div>
              ) : pageType === "adminEngReq" ? (
                <div ref={ref} className="popup" onClick={handleCancelEngRequest}>
                  <p className="red">Cancel Request</p>
                </div>
              ) : pageType === "adminSubReq" ? (
                <div ref={ref} className="popup" onClick={handleCancelSubRequest}>
                  <p className="red">Cancel Request</p>
                </div>
              ) : pageType === "clientEngineersReq" ? (
                <div ref={ref} className="popup" onClick={handleCancelEngRequest}>
                  <p className="red">Cancel Request</p>
                </div>
              ) : pageType === "clientSubReq" ? (
                <div ref={ref} className="popup" onClick={handleCancelSubRequest}>
                  <p className="red">Cancel Request</p>
                </div>
              ) : (
                                                <div ref={ref} className="popup">
                                                  <p onClick={() => handleEditAssignment(assignmentId)}>
                                                    Edit Assignment
                  </p>
                                                </div>
                                              ))}
          </td>
        ) : (
                  <td key={index}>{current || "-"}</td>
                );
      })}
    </StyledClientBar>
  );
};

const StyledClientBar = styled.tr`
  background: #ffffff;
  border: 1px solid rgba(33, 51, 79, 0.1);
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  color: #21334f;
  padding: 1.8rem 1.6rem;

  .dots {
    position: relative;
  }
  .dots:hover {
    cursor: pointer;
  }

  .popup {
    position: absolute;
    width: 150px;
    right: 35px;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    font-size: 1.4rem;
    padding: .5rem 1rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 2;
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
  }
  a{
    text-decoration: none;
  }

  .techpop {
    position: absolute;
    width: 175px;
    right: 180px;
    top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 2;
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .red {
    color: rgba(180, 0, 0, 0.4);
  }
  .ellipse {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: black;
    display: inline-block;
    margin-right: 0.3rem;
  }
  .client-btn {
    background: ${({ status }) =>
    status === "active"
      ? "#34A853"
      : status === "retained"
        ? "#40c463"
        : status === "residence"
          ? "#6cd287"
          : status === "completed"
            ? "#34A853"
            : status === "internship"
              ? "#8adba0"
              : status === "not_assigned"
                ? "#DB6969"
                : status === "internship"
                  ? "#48C76A"
                  : status.toLowerCase() === "terminated"
                    ? "#D34444"
                    : "#D34444"};
    color: white;
    border: none;
    margin: 0 auto;
    max-width: 120px;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 10px;
  }

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    .techpop {
      position: absolute;
      width: 175px;
      right: 60px;
      top: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #ffffff;
      padding: 1rem 2rem;
      border: 1px solid rgba(33, 51, 79, 0.1);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
      z-index: 2;
      border-radius: 10px;
      p:hover {
        display: inline-block;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
`;

export default ClientBar;
