import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import Select from "react-select";
import FormTitleSection from "./FormTitleSection";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import { useAdmin } from "../../../context/AdminState";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import { apiPut, apiGet } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { formatErrors, setSelect } from "../../../utils/helperFunctions";


const validationSchema = Yup.object({
  status: Yup.string(),
  assignmentDate: Yup.date(),
  resumptionDate: Yup.date().required("*Required")
  .when("assignmentDate",
    (assignmentDate, yup) => assignmentDate && yup.min(assignmentDate, "Resumption date cannot be before assignment date")),
  endDate: Yup.date(),
  engineerId: Yup.string().required("*Required"),
  vendorId: Yup.string().required("*Required"),
  workLocation: Yup.string()

})


const EditAssignmentForm = ({ onClick, handleEditAssignment, assignmentId, reload, setFieldError }) => {

  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  const [initialValues, setInitialValues] = useState({
    status: "",
    engineerId: "",
    vendorId: "",
    resumptionDate: "",
    workLocation: "",
    assignmentDate: "",
    endDate: ""
  });

  const { fetchAssignmentInfo, fetchUnassigned, organisationsList, assignmentStatusList } = useAdmin();

  useEffect(() => {
    fetchAsignmentDetails();
    fetchAssignmentInfo();
    fetchUnassigned();
    fetchEngineerListing();
    // eslint-disable-next-line
  }, [])


  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [engineers, setEngineers] = useState([])
  const [assignmentDetails, setAssignmentDetails] = useState({});
  const [contractDoc, setContractDoc] = useState({});

  const [contractDocError, setContractDocError] = useState("");


  useEffect(() => {
    setContractDocError("");
  }, [contractDoc]);

  const locationOption = [
    { value: "", label: "select option" },
    { value: "REMOTE", label: "remote" },
    { value: "ONSITE", label: "onsite" },
  ];


  const organizationOptions = organisationsList.map((eng) => ({ value: eng.id, label: eng.name }));

  const assignmentOptions = assignmentStatusList.map((eng) => ({ value: eng.id, label: eng.name }));


  const onSubmit = (allValues, { setFieldError }) => {

    const { assignmentId, comments, creditAllocated, email, graduationClass, majorStacks, name, otherTechStacks, profilePic, reports, statusDate, telephoneNo, vendor, ...rest } = allValues;

    rest.resumptionDate = formatDate(rest.resumptionDate);
    rest.assignmentDate = formatDate(rest.assignmentDate);
    rest.expectedEndDate = formatDate(rest.endDate);
    rest.assignmentStatus = rest.status;
    rest.organisationId = rest.vendorId;

    const { vendorId, status, ...values } = rest;

    let formData = new FormData();
    if (contractDoc) {
      formData.append("contract_upload", contractDoc);
    }
    formData.append(
      "assignment_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );

    editAssignment(formData, setFieldError);
  };

  // Fetch Engineers Listing
  const fetchEngineerListing = async () => {
    try {
      const res = await apiGet(`/admin/engineers`);
      let result = res.data.data.content;

      let newResult = setSelect(result, "engineers");

      setEngineers(newResult);
    } catch (err) {
      toast.error(err);
    }
  };

  // Fetch assignment Details
  const fetchAsignmentDetails = async () => {
    try {
      const res = await apiGet(`/assignments/${assignmentId}`);
      let result = res.data.data;

      setAssignmentDetails(result);
      setInitialValues(result);
    } catch (err) {
      toast.error(err)
    }
  };

  //create organization
  const editAssignment = async (formData, setFieldError) => {
    const errorFunctions = {
      others: setFieldError
    }
    setLoading(true);
    //make call to the api
    try {
      let response = await apiPut(
        `/assignments/${assignmentId}`,
        formData,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500 ? new Error("") : response.data;
      }
      setSuccess(true);
      setLoading(false);
      reload();

    } catch (err) {
      console.log({ err })
      setLoading(false);

      if (err.response.data.subErrors && err.response.data.subErrors.length !== 0) {
        formatErrors(err.response.data.subErrors, errorFunctions);
      }

      toast.error(
        err?.response?.data?.message || "Not able to update assignment at this time"
      );
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });


  useEffect(() => {

    formik.setValues(assignmentDetails);

    // eslint-disable-next-line
  }, [assignmentDetails, initialValues]);

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Edit Assignment" onClick={handleEditAssignment} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">

          <FormSelectComponent
            label="Engineer"
            name="engineerId"
            options={engineers}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.engineerId}
            error={
              formik.touched.engineerId && formik.errors.engineerId
                ? formik.errors.engineerId
                : null
            }
          />

          <label className="assig-label" htmlFor="assignment-status">
            Assignment Status
          </label>
          <Select
            className="assig-select"
            options={assignmentOptions}
            placeholder="Select status"
            name="status"
            onChange={(selectedOption) => {
              let d = selectedOption;
              let even = { target: { name: 'status', value: d.value } }
              formik.handleChange(even)
            }}
            onBlur={formik.handleBlur}
            type="select"
            value={{ label: formik.values.status, value: formik.values.status }}
            error={
              formik.touched.status && formik.errors.status
                ? formik.errors.status
                : null
            }
          />

          <FormSelectComponent
            label="Assign to"
            name="vendorId"
            options={organizationOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vendorId}
            error={
              formik.touched.vendorId && formik.errors.vendorId
                ? formik.errors.vendorId
                : null
            }
          />

          <label className="assig-label" htmlFor="work-loacation">
            Work Location
          </label>
          <Select
            className="assig-select"
            options={locationOption}
            placeholder="Select Location"
            name="workLocation"
            onChange={(selectedOption) => {
              let d = selectedOption;
              let even = { target: { name: 'workLocation', value: d.value } }
              formik.handleChange(even)
            }}
            onBlur={formik.handleBlur}
            type="select"
            value={{ label: formik.values.workLocation, value: formik.values.workLocation }}
            error={
              formik.touched.workLocation && formik.errors.workLocation
                ? formik.errors.workLocation
                : null
            }
          />


          <FormInputComponent
            label="Assignment Date"
            type="date"
            name="assignmentDate"
            placeholder="Enter assignment date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.assignmentDate}
            error={
              formik.touched.assignmentDate && formik.errors.assignmentDate
                ? formik.errors.assignmentDate
                : null
            }
            className="assig-date"
          />

          <FormInputComponent
            label="Resumption Date"
            type="date"
            name="resumptionDate"
            placeholder="Enter resumption date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.resumptionDate}
            error={
              formik.touched.resumptionDate && formik.errors.resumptionDate
                ? formik.errors.resumptionDate
                : null
            }
            className="assig-date"
          />
          <FormInputComponent
            label="Expected End Date"
            type="date"
            name="endDate"
            placeholder="Enter end date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            error={
              formik.touched.endDate && formik.errors.endDate
                ? formik.errors.endDate
                : null
            }
            className="assig-date"
          />


          <FormUploadComponent
            label="Upload Contract"
            error={contractDocError}
            selectedFile={contractDoc}
            setSelectedFile={setContractDoc}
          />


          <MyButton className="form-btn" loading={loading} type={"submit"} full>
            {(!loading && "Edit Assignment") || ""}
          </MyButton>
        </form>
        <div className="form-success">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully Edited an engineer assignment.
          </p>
          <MyButton className="form-btn success-btn" onClick={handleEditAssignment}>
            Okay, Go back to Engineer Page
          </MyButton>
        </div>
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
    width: 100%;
    height: 100vh;
    z-index: 10;
    overflow-y: scroll;
    background: white;
    border: 1px solid rgba(33, 51, 79, 0.15);
    .form-btn{
        width: 100%;
        margin-top: 3.2rem;
    }
    .form-section{
        padding: 4rem 4rem 0 4rem;
        overflow-y: scroll;
    }
    .form-title{
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 3.2rem;
    }
    input .assig-date{
      width: 100% !important;
    }
    .css-yk16xz-control {
        border-radius: 0;
        border: 1px solid rgba(33, 51, 79, 0.15) !important;
        margin: 0.5rem 0 2.4rem;
        height: 48px;
        font-size: 16px;
        color: green;
    }
    .css-1pahdxg-control{
        border: 1px solid rgba(33, 51, 79, 0.15) !important;
        box-shadow: none;
        height: 48px;
        margin: 0.5rem 0 2.4rem;

    }
    .css-1pahdxg-control:hover{
        border: 1px solid rgba(33, 51, 79, 0.15) !important;
        border-radius: 0;
    }
    .css-1okebmr-indicatorSeparator{
        display: none;
    }
    .css-tlfecz-indicatorContainer{
        padding-right: 16px;
    }
    .css-tj5bde-Svg{
        color: #21334F;
        width: 15px;
    }
    .css-1wa3eu0-placeholder{
        color: rgba(33, 51, 79, 0.8);
        font-size: 16px;
    }
    .css-g1d714-ValueContainer {
        font-size: 16px;
    }

    .assig-label{
        color: black;
        font-weight: 600;
        font-size: 16px;
    }
    .add-client-btn{
        margin-bottom: 23.8rem;
    }
    .client-form{
        display: ${({ success }) => (success ? "none" : "block")};
        
    }
    .form-success{
        display: ${({ success }) => (success ? "flex" : "none")};
        width: 100%;
        flex-direction: column;
        align-items: center;

    }
    .sucess-icon{
        width: 4.2rem;
        margin-bottom: 1.7rem;
    }
    .green-text{
        font-size: 2.4rem;
        margin-bottom: 2.2.rem;
    }
    .success-msg{
        width: 25.9rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
        margin-bottom: 3.8rem;
    }
    .success-btn{
        width: 100% !important;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 3.2rem;
    }
    .success-btn:hover,
    .success-btn:focus{
        background: #34A853;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
    }

`;

export default EditAssignmentForm;
