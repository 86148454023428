import React from 'react'
import MyButton from '../../landingPage/MyButton'

const ErrorPage = () => {
    return (
        <h1>
            Hello there you reached an error page here, click <MyButton href="/home">Here</MyButton> to go back
            
        </h1>
    )
}

export default ErrorPage
