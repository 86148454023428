import axios from "axios";
import { callData, subscribeData } from "../components/landingPage/form/data";

export const getRequestCallData = () => {
  return callData;
};

export const getSubscribeData = async () => {
  return await subscribeData();
};

// Generates select dropdown dynamically
export const generateDropdown = (options) => {
  return options.map((option, index) => {
    return (
      <option
        key={index}
        value={option.value}
        selected={index === 0}
        disabled={index === 0}
      >
        {option.value}
      </option>
    );
  });
};

export const mapEngineersToStack = (values = []) => {
  let result = {};
  values.forEach((item) => {
    let stack = item.stack === ".NET" ? "net" : item.stack.toLowerCase();
    if (stack in result) {
      result[stack] += +item.num_of_engineer;
    } else {
      result[stack] = +item.num_of_engineer;
    }
  });

  return result;
};

export const formatFormValues = (result) => {
  let fields = [];
  for (let key in result) {
    fields.push({ name: key, value: result[key] });
  }
  let formData = {
    submittedAt: Date.now(),
    fields: fields,
  };
  return formData;
};

// sets select dropdown from api call
export const setSelect = (result, str, switcher) => {

  let stackOptions = [{ value: "", label: `Select ${str ||  "Option"}` }];

  for (let i = 0; i < result.length; i++) {
    let newObj = {};
    newObj["value"] = switcher ? result[i].name: result[i].id;
    newObj["label"] = result[i].name;

    stackOptions.push(newObj);
  }
  return stackOptions;
};

// capitalizes a string
export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};


export const getCountryList = async ()=> {

  let response = await axios.get(`${process.env.REACT_APP_BASE_URL}/reference/countries`);
   
      let result = response.data.data.map((dat, index) => ({ value: dat.id, label: dat.name}));
     result.unshift({value: "", label: "Select Country"});

     return result;
}


export const randomString = (length, chars) => {
  var mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  var result = '';
  for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
}

function getCode(companyName){

  let nameArr =  companyName.split(" ");
  let result = "";

  result = nameArr[0]

  if(nameArr[0].length > 2){
    result = nameArr[0].substring(0, 3);
  }
  else{
    result = nameArr[0];
  }
  return result;
  
}
getCode("Lg");


export const formatErrors = (errorBody, errorSetter) => {
 
  for(let i=0; i < errorBody.length; i++){
    let keys = errorBody[i];
    if(errorSetter[keys.field]){
      errorSetter[keys.field](keys.message);
    }else{
      errorSetter['others'](keys.field, keys.message);
    }
  }
}

export const convertCurrencyToInt =(amount) => {
  let newNum = amount.split("")
  let amt = newNum
    .filter((a) => a !== "₦" && a !== "," && a)
    .join("");
  return parseInt(amt);
}
