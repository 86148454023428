import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from '../../landingPage/MyButton';
import FormInputComponent from './enterpriseFormInputs/FormInputComponent';
import FormTitleSection from './FormTitleSection';
import { toast } from "react-toastify";
import { apiPut, apiGet } from "../../../utils/apiHelpers";
import { ClipLoader } from 'react-spinners';



const validationSchema = Yup.object({
    name: Yup.string().required('*Required'),
    amount: Yup.string().required('*Required'),
    duration: Yup.number().required('*Required'),
    numOfEngineers: Yup.number().required('*Required'),
    description: Yup.string().required('*Required')
})



const EditPackageForm = ({ onClick, pckgId, handlePackageModal, reload }) => {

    const [success, setSuccess] = useState(false);
    const [pckgDetails, setPckgDetails] = useState({});
    const [loading, setLoading] = useState(false);



const [initialValues, setInitialValues] = useState({
    name: "",
    amount: "",
    duration: "",
    numOfEngineers: "",
    description: ""
  });

  useEffect(()=>{
      fetchPackageDetails();
          // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    editPackage(values);
        let newNum = values.amount.split("");
        let amt = newNum.filter((a) => a !== "₦" && a !== "," && a).join("");
        values.amount = parseInt(amt);
        editPackage(values);
    };

      //Edit Package
  const editPackage = async (values) => {
    //make call to the api
    setLoading(true);
    try {
      await apiPut(`/admin/packages/${pckgId}`, values, {}, true);

      setSuccess(true);
      setLoading(false);
      reload();

    } catch (err) {
      console.log({ err })
      setLoading(false);
      toast.error(
        err?.response?.data?.message || err.response?.data?.subErrors[0]?.message || "Not able to create client at this time"
      );
    }
  };

   // Fetch package Details
   const fetchPackageDetails = async () => {
    try {
      const res = await apiGet(
        `/admin/packages/${pckgId}`
      );
      let result = res.data.data;

      setPckgDetails(result);
      setInitialValues(result);
    } catch (err) {
        toast.error(err);
    }
  };


    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const closeForm = () => {
        formik.resetForm()
        onClick();
        setSuccess(false);
    }

    useEffect(() => {
        formik.setValues(pckgDetails)
            // eslint-disable-next-line
    }, [pckgDetails, initialValues]);

    return (
      <StyledCreatePackageForm success={success}>
        <FormTitleSection title="Edit Package" onClick={handlePackageModal} />
        <div className="form-section">
          <form onSubmit={formik.handleSubmit} className="client-form">
            <p className="form-font form-title">Package Details</p>
            <FormInputComponent
              label="Amount Paid"
              type="number"
              name="amount"
              prefix="₦"
              allowDecimals={false}
              placeholder="Enter amount paid"
              onChange={(e)=> {
                let newNum = e.target.value.split("");
                let amt = newNum.filter((a) => a !== "₦" && a !== "," && a).join("");
                let even = {
                  target: {
                    name: "amount",
                    value: amt,
                  },
                };
                formik.handleChange(even)
              }}
              onBlur={formik.handleBlur}
              value={formik.values.amount}
              error={
                formik.touched.amount && formik.errors.amount
                  ? formik.errors.amount
                  : null
              }
            />
            <FormInputComponent
              label="Package Name"
              type="text"
              name="name"
              placeholder="Enter package name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
            />
            <FormInputComponent
              label="Duration (value should be in years)"
              type="number"
              name="duration"
              placeholder="Enter duration"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.duration}
              error={
                formik.touched.duration && formik.errors.duration
                  ? formik.errors.duration
                  : null
              }
            />
            <FormInputComponent
              label="Number Of Engineers"
              type="number"
              name="numOfEngineers"
              placeholder="Enter number of Engineers"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.numOfEngineers}
              error={
                formik.touched.numOfEngineers && formik.errors.numOfEngineers
                  ? formik.errors.numOfEngineers
                  : null
              }
            />
            <FormInputComponent
              label="Description"
              type="text"
              name="description"
              placeholder="Enter description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : null
              }
            />
               <MyButton className="form-btn add-client-btn" type={"submit"} full>
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Edit Package"
            )}
          </MyButton>
          </form>
          <div className="form-success card px-2 py-2">
            <img
              src="/images/sucess-icon.svg"
              alt="green-tick"
              className="sucess-icon"
            />
            <h2 className="form-font green-text">Success</h2>
            <p className="form-font success-msg">
              You successfully updated your package.
            </p>
            <MyButton className="form-btn success-btn" onClick={closeForm}>
              Okay, view list of pacakges
            </MyButton>
          </div>
        </div>
      </StyledCreatePackageForm>
    );
}

const StyledCreatePackageForm = styled.div`
    width: 100%;
    height: 100vh;
    z-index: 10;
    overflow-y: scroll;
    background: white;
    border: 1px solid rgba(33, 51, 79, 0.15);
    .form-section{
        padding: 4rem 4rem 0 4rem;
        overflow-y: scroll;
    }
    .form-title{
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 3.2rem;
    }
    .add-client-btn{
        margin-bottom: 23.8rem;
    }
    .client-form{
        display: ${({ success }) => (success ? "none" : "block")};
        
    }
    .form-success{
        display: ${({ success }) => (success ? "flex" : "none")};
        width: 100%;
        flex-direction: column;
        align-items: center;

    }
    .sucess-icon{
        width: 4.2rem;
        margin-bottom: 1.7rem;
    }
    .green-text{
        font-size: 2.4rem;
        margin-bottom: 2.2.rem;
    }
    .success-msg{
        width: 25.9rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
        margin-bottom: 3.8rem;
    }
    .success-btn{
        width: 100% !important;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 3.2rem;
    }
    .success-btn:hover,
    .success-btn:focus{
        background: #34A853;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
    }

`;

export default EditPackageForm