import React, {useState} from "react";
import styled from "styled-components";
import ManageUsers from "./ManageUsers";
import SettingsLayout from './SettingsLayout';
import PasswordSecurity from './PasswordSecurity';
import Information from './Information';
import Role from './Role';
import OrganizationProfile from './OrganizationProfile';

const SettingsRender = ({ pageType }) => {
    const [active, setActive] =  useState("info")
  return (
    <StyledDiv>
      <h3 className="setting">Settings</h3>
      <div className="settings-grid-container mt-5">
        <div>
          {" "}
          <SettingsLayout
            active={active}
            setActive={setActive}
            pageType={pageType}
          />
        </div>
        <div>
          {active === "manage" ? (
            <ManageUsers pageType={pageType} />
          ) : active === "password" ? (
            <PasswordSecurity pageType={pageType} />
          ) : active === "info" ? (
            <Information pageType={pageType} />
          ) : active === "org-profile" ? (
            <OrganizationProfile pageType={pageType} />
          ) : pageType === "admin" ? (
            <Role pageType={pageType} />
          ) : null}
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  box-sizing: border-box;
  .setting {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .settings-grid-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 4rem;
  }
  @media only screen and (min-width: 321px) and (max-width: 768px) {
    .settings-grid-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4rem;
    }
  }
`;

export default SettingsRender;
