import React, { useState } from "react";
import styled from "styled-components";
import ListingInterface from "../../common/ListingInterface";
import { clientSubscriptionView } from "../../common/listingData";
import Layout from "../../common/Layout";

const ClientSubscriptionPage = ({ pageType }) => {
  const [activeModal, setActiveModal] = useState(false);

  const handleModal = () => setActiveModal(!activeModal);

  return (
    <Layout navType="client">
      <StyledDashboardPage >
            <ListingInterface
              details={clientSubscriptionView}
              handleModal={handleModal}
              pageType={pageType}
            />
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div`

`;

export default ClientSubscriptionPage;
