export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING = "LOADING";
export const VERIFIED = "VERIFIED";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
export const FETCH_NEW_CLIENT_SUCCESS = "FETCH_NEW_CLIENT_SUCCESS";
export const FETCH_ENGINEER_SUCCESS = "FETCH_ENGINEER_SUCCESS";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_CLIENT_SUB_SUCCESS = "FETCH_CLIENT_SUB_SUCCESS";
export const FETCH_ASSIGNMENT_INFO = "FETCH_ASSIGNMENT_INFO";
export const FETCH_CLIENT_ENG_SUCCESS = "FETCH_CLIENT_ENG_SUCCESS";
export const FETCH_STACK_SUCCESS = "FETCH_STACK_SUCCESS";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const ORG_SUB_SUCCESS = "ORG_SUB_SUCCESS";
export const FETCH_PACKAGE_SUCCESS = "FETCH_PACKAGE_SUCCESS";
export const FETCH_INDUSTRY_SUCCESS = "FETCH_INDUSTRY_SUCCESS";
export const FETCH_SQUAD_SUCCESS = "FETCH_SQUAD_SUCCESS";
export const ASSIGNMENT_LISTING = "ASSIGNMENT_LISTING";
export const FETCH_TECH_STACK_SUCCESS = "FETCH_TECH_STACK_SUCCESS";
export const FETCH_UNASSIGNED = "FETCH_UNASSIGNED";
export const FETCH_ENG_REQ_SUCCESS = "FETCH_ENG_REQ_SUCCESS";
export const FETCH_SUB_REQ_SUCCESS = "FETCH_SUB_REQ_SUCCESS";
export const FETCH_ADMIN_SUB_SUCCESS = "FETCH_ADMIN_SUB_SUCCESS";
export const FETCH_ADMIN_ENG_SUCCESS = "FETCH_ADMIN_ENG_SUCCESS";

