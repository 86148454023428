import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import InvoiceTableData from "./InvoiceTableData";
import { apiGet } from "../../../../utils/apiHelpers";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";


function OrganizationSubscription(props) { 

  const {isAuthenticated, orgRefId, subId} = props;
  const locations = useLocation();

  const { organisation } = locations.state;
  const { id } = useParams();
  const [packageName, setPackageName] = useState("");
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [noOfEngineer, setNoOfEngineer] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [availableCredit, setAvailableCredit] = useState(0);

  
const fetchSubscription = async () => {

  const orgId = JSON.parse(localStorage.getItem("client_details")).organisationId;

  try {
    const res = isAuthenticated === "admin" ? await apiGet(
      `/organisations/${orgRefId}/subscriptions/${subId}`
    ) : await apiGet(
      `/organisations/${orgId}/subscriptions/${id}`
    );
    let data = res.data.data;


    setPackageName(data.packageName);
    setAmount(data.amount);
    setStatus(data.status);
    setExpiryDate(data.expireDate);
    setStartDate(data.startDate);
    setNoOfEngineer(data.numEngineers);
    setAvailableCredit(data.availableCredit);
   
  } catch (err) {
    toast.error(err);
  }
};

useEffect(() => {
  fetchSubscription();
});


  let barSize;
  if (packageName === "Tier 1") {
    barSize = availableCredit * 2.5;
  } else {
    barSize = noOfEngineer - availableCredit;
  }

  return (
    <StyledClientHeader>
      <Link  to= {isAuthenticated === "admin" ? `/admin/subscriptions/sublisting/${orgRefId}` :  `/client/dashboard`} className="goBack">
        <img
          className="back-arrow-img"
          src="/images/back-arrow.svg"
          alt="back arrow"
        />
        <span className="go-back-text">Go Back</span>
      </Link>

      <div className="company-logo row mt-5">
        <div className="col-md-6 d-flex align-items-center">
          <span className="carbon-icon-span">
            <img
              className="carbon-icon"
              src="/images/carbon-icon.svg"
              alt="carbon-icon"
            />
          </span>
          <span className="carbon-logo-text">{organisation}</span>
        </div>

        <div className="col-md-6 align-self-right">
          <div className="status">
            <div className="status-text">Status</div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {status}
              </Dropdown.Toggle>

            </Dropdown>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-7 h-100">
          <Card style={{ padding: "2rem 2rem" }}>
            <Card.Body>
              <div className="row">
                <div className="col-md-6 ">
                  <div>
                    <span>
                      <span className="teir">{packageName}</span>
                      <span className="plan">Plan</span>
                    </span>
                  </div>
                  <div className="mt-4">
                    <span>
                      <img
                        className="download-icon"
                        src="/images/download-icon.svg"
                        alt="download-icon"
                      />
                    </span>
                    <span className="view-contract">View Contract</span>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className=" pr-4 dev-fee-div">
                    <span className="dev-fee"> &#8358;{amount}</span>
                    <span className="year">/ year</span>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-8 ">
                  {packageName === "Tier 1" ? (
                    <p className="number-of-dev">
                      {4 - availableCredit} of 4 Engineers Used
                    </p>
                  ) : (
                    <p className="number-of-dev">
                      {10 - availableCredit} of 10 Engineers Used
                    </p>
                  )}
                  <div className="progress">
                    <div
                      className="bar"
                      style={{ width: `${barSize}0%` }}
                    ></div>
                  </div>
                </div>

              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-5 second-card">
          <Card className="h-100" style={{ padding: "1rem" }}>
            <Card.Body>
              <div className="row px-4">
                <div className="col-md-12 px-0">
                  <h2>Next Payment</h2>
                </div>
                <div className="col-md-12 px-0 mt-2">
                  <h1>on {expiryDate}</h1>
                </div>
                <Button className="reminder-btn px-5 py-3">
                  Cancel Subscription
                </Button>{" "}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div style={{ marginTop: "5rem" }}>
        <p>
          <span className="invoice-name">Subscription Invoices</span>{" "}
          <span className="invoice-number">(2)</span>
        </p>

        <InvoiceTableData startDate={startDate}/>
      </div>
    </StyledClientHeader>
  );
}

const StyledClientHeader = styled.div`

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    #fafffb;
font-family: Mulish;
  .company-logo {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .back-arrow-img {
    width: 2rem;
    font-size: 8rem;
    margin-bottom: 0.7rem;
  }
  .goBack {
    color: #21334f;
    text-decoration: none;
  }
  .go-back-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0.6rem;
  }
  .carbon-icon {
    height: 4.5rem;
    width: 5.5rem;
  }
  .carbon-icon-span {
    background: #fff;
    padding: 1rem 0.5rem;
  }
  .carbon-logo-text {
    margin-left: 2rem;
    font-size: 3rem;
  }
  .status{
      position: absolute;
      right: 3rem;
  }
  .status-text{
      font-size: 1.5rem;
      margin-bottom: 1rem;
  }
  button#dropdown-basic.dropdown-toggle{
      background: #fff !important;
      color:rgba(33, 51, 79, 1) !important;
      border: none;
      font-size: 1.5rem;
  }
  button#dropdown-basic.dropdown-toggle::after{
      margin-left: 3rem;
  }
  .teir{
      background: #21334F;
      padding: 0.5rem 0.7rem;
      color: #fff;
      font-size: 1.5rem;
  }
  .plan{
      font-size: 2rem;
      margin-left: 1rem;
  }
  .card{
      background: #FFFFFF;
      border: 1px solid rgba(33, 51, 79, 0.1);
      box-sizing: border-box;
  }
  .dev-fee{
      font-size: 3rem;
      font-weight: 800;
      color: rgba(33, 51, 79, 1);
  }
  .dev-fee-div{
      position: absolute;
      right: 0rem;
  }
  .download-icon{
      height: 1.5rem;
      width: 2rem;
      margin-bottom: 0.7rem;
  }
  .view-contract{
      font-size: 1.5rem;
      font-weight: 600;
      color: #34A853;
      margin-left: 0.5rem; 
  }
  .number-of-dev{
      font-size: 1.5rem;
  }
  .year{
      font-size: 1.5rem;
  }
  .reminder-btn{
      background: transparent !important;
      color: rgba(33, 51, 79, 0.46);
      border: 1px solid rgba(33, 51, 79, 0.1);
      box-sizing: border-box;
      margin-top: 4rem;
      font-size: 1.6rem;
      font-weight: 600;
  }
  .change-btn{
      background: linear-gradient(96.67deg, #34A853 0%, #B8D344 100%);
      color: #fff;
      font-size: 1.6rem;
      border: none;
  }
  .progress {
  padding:0;
  width:70%;
  height:10px;
  overflow: hidden;
  background:#e5e5e5;
  border-radius:6px;
}

.bar {
position:relative;
  float:left;
  min-width:1%;
  height:100%;
  background: #34A853;
}
.invoice-name{
    font-size: 3rem;
    color: rgba(33,51,79,1)

}
.invoice-number{
    font-size: 2rem;
    color: rgba(33,51,79,1);
    margin-left: 0.6rem;
}


  @media only screen and (min-width: 321px) and (max-width: 767px) {
      width: 100%;
    padding: 2rem 2.5rem;
    .div-change{
    margin-top: 3rem;
    }
   .carbon-icon{
    height: 3.5rem;
    width: 4.5rem;
   }
   .carbon-logo-text{
    font-size: 2rem;
   }
   .status{
       margin-top: 2rem;
   }
   .status, .dev-fee-div{
       position: relative;
       right: 0px;
   }

   .second-card{
       margin-top: 2rem !important;
   }

  }
  @media screen and (max-width: 1170px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;

export default OrganizationSubscription;
