import React from 'react'
import styled from 'styled-components';

const Pagination = ({clientsPerPage, totalClients, paginate, currentPage }) => {
    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalClients/clientsPerPage); i++){
        pageNumbers.push(i);
    }

    return (
        <StyledPaginationn currentPage={currentPage}>
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number? "active": ""}`}>
                        <button onClick={() => paginate(number)}  className="page-link">{number}</button>
                    </li>
                ))}

            </ul>
        </StyledPaginationn>
    )
}
const StyledPaginationn = styled.nav`
  display: flex;
  justify-content: center;
  height: 44px;
  .page-link {
    display: inline-block;
    font-size: 16px;
    color: black;
    padding: 1.2rem 2.5rem;
    z-index: 0;
  }
  .batch-page{
      display: inline-block;
      margin-left: .8rem;
  }
  .page-item.active .page-link, button.page-link:active {
    background-color: #34a853 !important;
    border: none;
    z-index: 0;
    outline: none;
    color: white;
  }

`;
export default Pagination
