import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import { BiSearch } from "react-icons/bi";
import Select from "react-select";
import Pagination from "../atoms/Pagination";
import { apiGet, apiDelete, apiPut } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import CustomLoader from "../atoms/CustomLoader";
import ClientBar from "../admin/ClientBar";
import FormModal from "../form/FormModal";
import AddClientForm from "../form/AddClientForm";
import { useAdmin } from "../../../context/AdminState";
import CreateSubForm from "../form/CreateSubForm";
import AddEngForm from "../form/AddEngForm";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import EditEngForm from "../form/EditEngForm";
import CreatePackageForm from "../form/CreatePackageForm";
import UpdateClientForm from "../form/UpdateClientForm";
import UpdateSub from "../form/UpdateSub";
import EditPackageForm from "../form/EditPackageForm";
import EditAssignmentForm from "../form/EditAssignmentForm";
import CreateTechstack from "../form/CreateTechstack";
import CreateIndustry from "../form/CreateIndustry";
import UpdateIndustry from "../form/UpdateIndustry";
import UpdateTechstackForm from "../form/UpdateTechstackForm";
import CreateReport from "../form/CreateReport";
import CreateComment from "../form/CreateComment";
import AddUserForm from "../form/AddUserForm"
import AddSquad from "../form/AddSquad"
import RequestEngForm from "../form/RequestEngForm"
import AddSubRequest from "../form/AddSubRequest";

const ListingInterface = ({ details, pageType, refId }) => {
  const { loading, gf, dashboardData, fetchSubReq, fetchEngReq, viewUsers, fetchStackOptions } =
    useAdmin();
  
  

  const {
    placeholder,
    btnInfo,
    title,
    headers,
    options,
    tableDetails,
    path,
    auth,
  } = details;

 

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const num = 6;
  const [dataPerPage] = useState(num);
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [activeModal, setActiveModal] = useState(false);
  const handleModal = () => setActiveModal(!activeModal);
  const [activeSubModal, setActiveSubModal] = useState(false);
  const [activeEngModal, setActiveEngModal] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [techstackModal, setTechstackModal] = useState(false);
  const [industryModal, setIndustryModal] = useState(false);
  const [, setLoad] = useState(false);

  const [editSubModal, setEditSubModal] = useState(false);
  const [editAssigModal, setEditAssigModal] = useState(false);

  const [orgId, setOrgId] = useState(null);
  const [engId, setEngId] = useState(null);
  const [subId, setSubId] = useState(null);
  const [pckgId, setPckgId] = useState(null);
  const [industryId, setIndustryId] = useState(null);
  const [techstackId, setTechstackId] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  const [packageModal, setPackageModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentEng, setCurrentEng] = useState(null);
 
 

  
  //create state for pagination and number of pages
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;


  const { id } = useParams();

  // Fetch engineers Details
  const fetchEngineers = async () => {
    try {
    
     await apiGet(
        `/admin/engineers`
      );
     
    } catch (err) {
        toast.error(err);
    }
  };

  


  const searchId = auth === "client" ? JSON.parse(
    localStorage?.getItem("client_details")
  ).organisationId : 0;


  const handleSubModal = () => {
    setOrgId(id)
    setActiveSubModal(!activeSubModal);
  };
  const handlePackageModal = (id) => {
    setPckgId(id);
    setPackageModal(!packageModal);
  };

  const handleTechStack = (id) => {
    setTechstackId(id);
    setTechstackModal(!techstackModal);
  };

  const handleIndustry = (id) => {
    setIndustryId(id);
    setIndustryModal(!industryModal);
  };

  const handleEngModal = (id) => {
    setEngId(id);
    setEdit(!edit);
    setActiveEngModal(!activeEngModal);
  };

  //opens and closes edit subscription modal
  const handleEditSubModal = (id) => {
    setSubId(id);
    setEditSubModal(!editSubModal);
  };

  //opens and closes edit subscription modal
  const handleEditAssignment = (id) => {
    setAssignmentId(id);
    setEditAssigModal(!editAssigModal);
  };

  const handleClientModal = (id) => {
    setOrgId(id);
    setClientModal(!clientModal);
  };

  const reloader = () => {
    setReload(!reload);
  }
  // Toggle pageType
  useEffect(() => {
    gf(pageType, id);
    // eslint-disable-next-line
  }, [pageType, id, reload]);

  // Toggle pageType
  useEffect(() => {
    fetchEngineers();
    // eslint-disable-next-line
  }, [pageType]);

  useEffect(() => {
    fetchStackOptions();
    //  eslint-disable-next-line
  }, []);

  //Reset current data when search is empty
  useEffect(() => {
    if (search === "") {
      setCurrentData(
        (dashboardData[pageType] || []).slice(indexOfFirstData, indexOfLastData)
      );
    }
  }, [search, dashboardData, indexOfFirstData, indexOfLastData, pageType]);

  const handelDate = (e) => {
    if (dateFrom !== "" && dateTo !== "") {
      handleDateSearch();
    }
  };

  useEffect(() => {
    if (pageType === "sub") {
      handelDate();
    }
    // eslint-disable-next-line
  }, [dateTo, dateFrom]);

  // handle search
  const handleDateSearch = async () => {
    setLoad(true);
    try {
      const res = await apiGet(
        `/organisations/${id}/subscriptions/search?dateFrom=${dateFrom}?dateTo=${dateTo}`,
        {},
        auth
      );

      if (res.data.data.content.length === 0) {
        setCurrentData(res.data.data.content.slice());

        toast.info(`No match found`);
        setLoad(false);
        setTimeout(() => {
          setCurrentData(
            (dashboardData[pageType] || []).slice(
              indexOfFirstData,
              indexOfLastData
            )
          );
        }, 4000);
      } else {
        setCurrentData(res.data.data.content.slice());
        setLoad(false);
      }
    } catch (err) {
      console.log({ err })
      toast.error(err.message);
    }
    setLoad(false);
  };

  const handleSearch = async (e) => {
    if (e.keyCode === 13) {
      setLoad(true);
      try {
        const res = await apiGet(
          `${typeof path == "string" ? path : path(searchId)
          }/search?search=${search}`,
          {},
          auth
        );

        if (res.data.data.content.length === 0) {
          setCurrentData(res.data.data.content.slice());

          toast.info(`No match found`);
          setLoad(false);
          setTimeout(() => {
            setCurrentData(
              (dashboardData[pageType] || []).slice(
                indexOfFirstData,
                indexOfLastData
              )
            );
          }, 4000);
        } else {
          setCurrentData(res.data.data.content.slice());
          setLoad(false);
        }
      } catch (err) {
        console.log({ err })
        toast.error(err.message);
        setLoad(true);
      }
    }
  };

  useEffect(() => {
    setCurrentData(
      (dashboardData[pageType] || []).slice(indexOfFirstData, indexOfLastData)
    );
  }, [pageType, dashboardData, indexOfLastData, indexOfFirstData]);

  // fetch client user
  const [updateId, setUpdateId] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const fetchClientSingleManager = async () => {
    try {
      let response = await apiGet(`/organisations/${id}/users`, {}, "client");
      if (response.error) {
        throw response.data.response.status === 500 ? new Error("") : response.data;
      }
      setCurrentData(response.data.data.content);

    } catch (err) {
      console.log({ err })
      toast.error(err?.response?.data?.message || "error fetching user data");
    }
  };

  useEffect(() => {
    if (pageType === "users") {
      fetchClientSingleManager();
    }
    // eslint-disable-next-line
  }, []);

  const viewUser = async (item) => {
    setUpdateId(item.id);
    handleModal();
    setIsEditing(true);
  };

  //delete users
  const deleteUsers = async (item) => {
    try {
      await apiDelete(
        `/organisations/${id}/users/${item.id}`,
        {}
      );
      viewUsers(id);
    }
    catch (err) {
      console.log(err)
    }
  }

  //activate users
  const activateUser = async (item) => {
    item.status = "ACTIVE";
    const userPayload = {
      ...item,
    };

    await apiPut(
      `/organisations/${id}/users/${item.id}/activate`,
      userPayload,
      {}
    );
    window.location.reload();
  };

  //deactivate users
  const deactivateUser = async (item) => {
    item.status = "INACTIVE";
    const userPayload = {
      ...item,
    };

    await apiPut(
      `/organisations/${id}/users/${item.id}/deactivate`,
      userPayload,
      {}
    );
    window.location.reload();
  };

  // fetch squad
  const fetchSquad = async () => {

    try {
      let response = await apiGet(`/admin/squads`, {});
      
      if (response.error) {
        throw response.data.response.status === 500 ? new Error("") : response.data;
      }

      setCurrentData(response.data.data.content);

    } catch (err) {
      console.log({ err: err });
      toast.error(err?.response?.data?.message || "error fetching squad details")
    }
  };
 
  return loading ? (
    <CustomLoader />
  ) : (
    <StyledClientPage className={"styled-client-page"}>
      {pageType === "users" ? (
        <Link to={`/admin/dashboard`} className="goBack">
          <img
            className="back-arrow-img"
            src="/images/back-arrow.svg"
            alt="back arrow"
          />
          <span className="go-back-text">Go Back</span>
        </Link>
      ) : pageType === "sub" ? (
        <Link to={`/admin/dashboard`} className="goBack">
          <img
            className="back-arrow-img"
            src="/images/back-arrow.svg"
            alt="back arrow"
          />
          <span className="go-back-text">Go Back</span>
        </Link>
      ) : pageType === "assignments" ? (
        <Link to={`/admin/engineers`} className="goBack">
          <img
            className="back-arrow-img"
            src="/images/back-arrow.svg"
            alt="back arrow"
          />
          <span className="go-back-text">Go Back</span>
        </Link>
      ) : (
        ""
      )}

      <div className="client-header">
        {pageType !== "overview" && <h4>{title || "Clients"}</h4>}
        {pageType === "client_subscriptions" ? (
          ""
        ) : pageType === "overview" ? (
          ""
        ) : pageType === "client_engineers" ? (
          ""
        ) : pageType === "subscriptions" ? (
          ""
        ) : pageType === "adminEngReq" ? (
          ""
        ) : pageType === "adminSubReq" ? (
          ""
        ) : pageType === "assignments" ? (
          ""
        ) : pageType === "clientEngineersReq" ? (
          
          <MyButton onClick={handleModal} className="btn-comp">
            {btnInfo || "Add New Client"}
          </MyButton>
        ) : pageType === "clientSubReq" ? (
          <MyButton onClick={handleShow} className="btn-comp">
            {btnInfo || "Add New Client"}
          </MyButton>
        ) : pageType === "sub" ? (
          <MyButton onClick={handleSubModal} className="btn-comp">
            {btnInfo || "Add New Client"}
          </MyButton>
        ) : (
          <MyButton onClick={handleModal} className="btn-comp">
            {btnInfo || "Add New Client"}
          </MyButton>
        )}
      </div>
      <div className="client-input">
        {pageType === "sub" ? (
          <div className="date-search">
            <label htmlfor="datefrom">
              <span className="span-label">Date From:</span>
              <input
                className="mt-1"
                type="date"
                id="datefrom"
                onChange={(e) => {
                  setDateFrom(e.target.value);
                  handelDate(e);
                }}
                value={dateFrom}
              />
            </label>
            <label htmlfor="dateto">
              <span className="span-label">Date To:</span>
              <input
                className="mt-1"
                type="date"
                id="dateto"
                onChange={(e) => {
                  setDateTo(e.target.value);
                  handelDate(e);
                }}
                value={dateTo}
              />
            </label>
          </div>
        ) : null}
        {pageType !== "sub" &&
          pageType !== "overview" &&
          pageType !== "squad" &&
          pageType !== "package" &&
          pageType !== "industry" &&
          pageType !== "techstack" ? (
          <div className="client-search mt-4">
            <BiSearch className="search-icon" size={20} />
            <input
              type="text"
              placeholder={placeholder || "Search for clients"}
              className=""
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyUp={(e) => handleSearch(e)}
            />
          </div>
        ) : null}
        {pageType !== "squad" &&
          pageType !== "overview" &&
          pageType !== "package" &&
          pageType !== "industry" &&
          pageType !== "techstack" ? (
          <div className="client-filter mt-4">
            <Select
              className="my-select"
              options={options}
              placeholder="Filter"
            />
          </div>
        ) : null}
      </div>

      <div className="styled-table-parent">
        {currentData && (
          <table cellSpacing="0" className="table client-table">
            <thead>
              <tr>
                {headers.map((item, index) => (
                  <th key={index} scope="col">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {currentData.length ? (
                currentData.map((item, index) => (
                  <ClientBar
                    key={index}
                    id={item.id}
                    item={item}
                    tableDetails={tableDetails}
                    pageType={pageType}
                    handleSubModal={handleSubModal}
                    handleEngModal={handleEngModal}
                    handleClientModal={handleClientModal}
                    setEng={setCurrentEng}
                    routeId={id}
                    refId={refId}
                    setCurrentData={setCurrentData}
                    currentData={currentData}
                    handleEditSubModal={handleEditSubModal}
                    handlePackageModal={handlePackageModal}
                    handleEditAssignment={handleEditAssignment}
                    handleIndustry={handleIndustry}
                    handleTechStack={handleTechStack}
                    viewUser={viewUser}
                    deleteUsers={deleteUsers}
                    deactivateUser={deactivateUser}
                    activateUser={activateUser}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="no-data">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {loading && <CustomLoader />}
      {show && pageType === "clientSubReq" ? (
        <AddSubRequest
          show={show}
          setShow={setShow}
          handleShow={handleShow}
          fetchSubRequest={fetchSubReq}
        />
      ) : null}
      {activeModal && (
        <div className="form-modal">
          <FormModal className="form">
            {pageType === "sub" ? (
              <CreateSubForm
                onClick={handleModal}
                orgId={orgId}
                reload={reloader}
              />
            ) : pageType === "clients" ? (
              <AddClientForm onClick={handleModal} reload={reloader} />
            ) : pageType === "engineers" ? (
              <AddEngForm onClick={handleModal} reload={reloader} />
            ) : pageType === "package" ? (
              <CreatePackageForm onClick={handleModal} reload={reloader} />
            ) : pageType === "industry" ? (
              <CreateIndustry onClick={handleModal} reload={reloader} />
            ) : pageType === "techstack" ? (
              <CreateTechstack onClick={handleModal} reload={reloader} />
            ) : pageType === "client_subscription" ? (
              <CreateReport onClick={handleModal} reload={reloader} />
            ) : pageType === "client_subscription" ? (
              <CreateComment onClick={handleModal} reload={reloader} />
            ) : pageType === "squad" ? (
              <AddSquad
                onClick={handleModal}
                fetchSquad={fetchSquad}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                pageType="client"
                OrganizationId={id}
                updateId={updateId}
                reload={reloader}
              />
            ) : pageType === "users" ? (
              <AddUserForm
                onClick={handleModal}
                fetchClientSingleManager={fetchClientSingleManager}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                pageType="client"
                OrganizationId={id}
                updateId={updateId}
              />
            ) : pageType === "clientEngineersReq" ? (

              <RequestEngForm
                onClick={handleModal}
                OrgId={searchId}
                fetchEngReq={fetchEngReq}
              />
            ) : (
              ""
            )}
          </FormModal>
        </div>
      )}
      
      {activeSubModal && (
        <div className="form-modal">
          <FormModal className="form">
            <CreateSubForm
              orgId={orgId}
              reload={reloader}
              onClick={handleSubModal}
            />
          </FormModal>
        </div>
      )}
     
      {clientModal && (
        <div className="form-modal">
          <FormModal className="form">
            <UpdateClientForm
              orgId={orgId}
              reload={reloader}
              onClick={handleClientModal}
            />
          </FormModal>
        </div>
      )}
      {activeEngModal && (
        <div className="form-modal">
          <FormModal className="form">
            <EditEngForm
              edit={edit}
              engId={engId}
              orgId={orgId}
              handleEngModal={handleEngModal}
              engData={currentEng}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}
      {editAssigModal && (
        <div className="form-modal">
          <FormModal className="form">
            <EditAssignmentForm
              assignmentId={assignmentId}
              handleEditAssignment={handleEditAssignment}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}
      {industryModal && (
        <div className="form-modal">
          <FormModal className="form">
            <UpdateIndustry
              onClick={handleModal}
              industryId={industryId}
              handleIndustry={handleIndustry}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}
      {techstackModal && (
        <div className="form-modal">
          <FormModal className="form">
            <UpdateTechstackForm
              techstackId={techstackId}
              handleTechStack={handleTechStack}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}
      {packageModal && (
        <div className="form-modal">
          <FormModal className="form">
            <EditPackageForm
              edit={edit}
              onClick={handlePackageModal}
              pckgId={pckgId}
              handlePackageModal={handlePackageModal}
              engData={currentEng}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}

      {editSubModal && (
        <div className="form-modal">
          <FormModal className="form">
            <UpdateSub
              onClick={handleEditSubModal}
              subId={subId}
              orgId={id}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}
      {pageType !== "overview" && (
        <div className="page-parent">
          <Pagination
            currentPage={currentPage}
            clientsPerPage={dataPerPage}
            totalClients={
              dashboardData[pageType] ? dashboardData[pageType].length : []
            }
            // batch={true}
            // fetchNext={fetchNext}
            paginate={(pageNumber) => setCurrentPage(pageNumber)}
            className="pagination"
          />
        </div>
      )}
    </StyledClientPage>
  );
};

const StyledClientPage = styled.div`
  .styled-table-parent {
    overflow-y: auto;
    white-space: nowrap;
  }
  
  .client-input {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;
  }

  .next-arr {
    background: white;
    background-image: url("/images/right-arrow.svg");
    height: 44px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1.2rem 2.5rem;
    margin-left: 2rem;
    z-index: 2;
  }
  .next-arr:hover,
  .next-arr:focus,
  .next-arr:active,
  .next-arr::selection {
    background: #34a853;
    background-image: url("/images/right-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5%;
  }
  .client-filter {
    max-width: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    height: 5rem;
  }
  .go-back-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0.6rem;
  }

  .back-arrow-img {
    width: 2rem;
    font-size: 8rem;
    margin-bottom: 0.7rem;
  }
  .goBack {
    color: #21334f;
    text-decoration: none;
  }

  .client-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 25px;
    th,
    td {
      padding: 15px 10px;
      text-align: left;
      border-top: none;
      border-bottom: none;
    }

    tr:first-child {
      th {
        font-size: 16px;
        padding: 0;
      }
    }
  }
  .search-icon {
    margin-left: 1.5rem;
  }
  
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-yk16xz-control {
    border: none;
    height: 5rem;
  }
  .css-1pahdxg-control {
    border: none;
    height: 5rem;
    box-shadow: none;
  }
  .css-1pahdxg-control:hover {
    border: 1px #34a853 solid !important;
  }
  input,
  .my-select {
    height: 5rem;
    font-size: 1.6rem;
    border: none;
    outline: none;
    color: #21334f;
    padding-left: 1.6rem;
    width: 100%;
  }
  .css-1hb7zxy-IndicatorsContainer {
    padding-bottom: 8px;
  }
  .css-1wa3eu0-placeholder {
    top: 40%;
  }
  .my-select {
    padding-bottom: 1rem;
  }

  .custom-select:focus {
    border: none;
  }
  input:focus {
    border: none;
  }
  .client-search {
    width: 60%;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(33, 51, 79, 0.1);
  }
  .client-search:focus {
    border: 1px #34a853 solid;
    border-radius: 0.5rem;
  }

  input::placeholder {
    font-size: 16px;
    line-height: 20px;
    color: rgba(33, 51, 79, 0.6);
  }
  .client-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 4rem 0 3.5rem;
  }
  .client-header h4 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .no-data {
    text-align: center !important;
    font-size: large;
  }
  .date-search {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
   input[type="date"] {
    border: 1px solid #34a853;
    border-radius: 5px;
  }

  .span-label{
    font-size: 1.5rem;
    font-weight: 600;
  }

  @media screen and (max-width: 1170px) {
    width: 100%;
    .client-header {
      margin-top: 5rem;
    }
  }
  @media screen and (max-width: 770px) {
    .client-input {
      width: 100%;
    }
  }
`;
export default ListingInterface;