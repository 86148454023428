import React,{useState} from "react";
import { Route, Redirect } from "react-router-dom";



const ProtectedRoutes = ({ component: Component, role, permission, ...rest }) => {
  const [acceptedPerm, ] = useState(permission.reduce((data, perm) => {
    return { ...data, [perm]: true}
  }, {}))
  
  

  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles?.split(",");

  


  return (
    <Route
      {...rest}
      render={(props) => {

           if(permArr.some((perm) => acceptedPerm[perm]) ){
          return <Component  {...props} />;
        }

        else {

          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoutes;
