import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { apiGet, apiPut} from "../../../../utils/apiHelpers";
import AddUserForm from '../../form/AddUserForm';
import FormModal from '../../form/FormModal';
import Pagination from "../../atoms/Pagination";
import { toast } from "react-toastify";

const ManageUsers = ({ pageType }) => {
  let OrganizationDetails = JSON.parse(localStorage.getItem("client_details"));
  let OrganizationId = OrganizationDetails.organisationId;

  const [activeModal, setActiveModal] = useState(false);
  const handleModal = () => setActiveModal(!activeModal);
  const [idOfTable, setIdOfTable] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const num = Math.floor((0.6 * window.screen.height) / 90);
  const [dataPerPage] = useState(num - 1);
  const [currentData, setCurrentData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [updateId, setUpdateId] = useState(0);


  //create state for pagination and number of pages
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };
  const showModal = (index) => {
    setIdOfTable(index);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });  

  const [res, setRes] = useState([]);
  const fetchClientSingleManager = async () => {
    try {
      let response = await apiGet(
        `/organisations/${OrganizationId}/users`,
        {},
        "client"
      );
      setRes(response.data.data.content);
    } catch (err) {
      toast.error(err);
    }
  };

  const fetchAdminSingleManager = async () => {
    try {
      let response = await apiGet(`/admin/users`);
      setRes(response.data.data.content);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    pageType === "client"
      ? fetchClientSingleManager()
      : fetchAdminSingleManager();
    
     // eslint-disable-next-line
  }, []);


  useEffect(() => {
    setCurrentData((res || []).slice(indexOfFirstData, indexOfLastData));
  }, [pageType, res, indexOfLastData, indexOfFirstData]);


  const activateUser = async (item) => {
    const userPayload = {
      ...item,
      status: "ACTIVE"
    };

    await apiPut(`/admin/users/${item.id}/activate`, userPayload, {});
    fetchAdminSingleManager();
  };

  const deActivateUser = async (item) => {
    const userPayload = {
      ...item,
      status: "INACTIVE",
    };

    await apiPut(`/admin/users/${item.id}/deactivate`, userPayload, {});
fetchAdminSingleManager();
  };

  const deleteUser = async (item) => {
  };
  const viewUser = async (item) => {
    setUpdateId(item.id);
    handleModal();
    setIsEditing(true);
  };


  return (
    <StyledDiv isEditing={isEditing}>
      <StyledClientView>
        <div>
          <div className="row">
            <div className="col-md-8  ">
              <h2 className="mt-4 manager">Manager Users</h2>
            </div>

            <div className="col-md-4 px-0">
              <div className=" add-eng">
                <Button
                  variant="primary"
                  className="add-btn"
                  onClick={handleModal}
                >
                  Add New Users
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-5 table-container">
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr className="grid-container" style={{ display: "flex" }}>
                  <th className="heading-name pl-4">Name</th>
                  <th className="heading-name pl-4">Email address</th>
                  <th className="heading-name ">Phone Number</th>
                  <th className="heading-name ">Role</th>
                  <th className="heading-name ">Action</th>
                </tr>
              </thead>
              <tbody className="table-body" style={{ width: "100%" }}>
                {currentData.map((item, index) => (
                  <tr
                    key={index}
                    className="grid-container bg-white"
                    style={{ display: "flex" }}
                  >
                    <td className="">
                      <span className="ml-4 table-text-name">
                        <div
                          className="others"
                          title={"sfsdfsdfsfdsdfsdfsdfss"}
                        >
                          {item.name}
                        </div>
                      </span>
                    </td>

                    <td className="pl-4">
                      <span className="ml-4 table-text-name">
                        <div className="others">{item.email}</div>
                      </span>
                    </td>
                    <td className="pl-4">{item.phone}</td>
                    <td className="pl-4">{item.role}</td>
                    <td
                      className="pl-4"
                      style={{ position: "relative" }}
                      onClick={() => showModal(index)}
                    >
                      <img
                        className="action-icon"
                        src="/images/action-dots.svg"
                        style={{ width: "3.5rem" }}
                        alt="action"
                      />

                      {index === idOfTable ? (
                        <div ref={ref} className="popup">
                          <p onClick={() => viewUser(item)}>View User</p>
                          {pageType === "admin" ? (
                            <>
                              <p onClick={() => activateUser(item)}>
                                Activate User
                              </p>
                              <p
                                onClick={() => deActivateUser(item)}
                                className="red"
                              >
                                Deactivate User
                              </p>
                            </>
                          ) : null}
                          <p className="red" onClick={() => deleteUser(item)}>
                            Remove User
                          </p>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="page-parent">
              <Pagination
                currentPage={currentPage}
                clientsPerPage={dataPerPage}
                totalClients={res ? res.length : []}
                paginate={(pageNumber) => setCurrentPage(pageNumber)}
                className="pagination"
              />
            </div>

            {activeModal && (
              <div className="form-modal">
                <FormModal className="form">
                  <AddUserForm
                    onClick={handleModal}
                    fetchClientSingleManager={fetchClientSingleManager}
                    fetchAdminSingleManager={fetchAdminSingleManager}
                    pageType={pageType}
                    OrganizationId={OrganizationId}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    updateId={updateId}
                  />
                </FormModal>
              </div>
            )}
          </div>
        </div>
      </StyledClientView>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  box-sizing: border-box;
  td {
    width: 20%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    font-size: 1.5rem;
    word-break: break-all;
  }
  tr:not(:first-child) {
    margin-top: 2rem;
  }
  .table-container {
    overflow-y: scroll;
  }
  th {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    font-size: 1.4rem;
  }

  .manager {
    font-size: 2rem;
    font-weight: 600;
  }

  .red {
    color: rgba(180, 0, 0, 0.4);
  }
  .popup {
    position: absolute;
    width: 175px;
    right: 50px;
    top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: ${({ isEditing }) => (isEditing ? 0 : 3)};
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
    }
  }
`;

const StyledClientView = styled.div`
  .add-eng {
    position: absolute;
    right: 0rem;
  }
  .add-btn {
    padding: 1rem 2rem;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    border: none;
    font-size: 1.5rem;
  }

  .heading-name {
    font-size: 1.5rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
  }
  .page-parent {
    margin-top: 7rem;
  }

  @media only screen and (min-width: 321px) and (max-width: 768px) {
    width: 100%;
    padding: 2rem 2.5rem;
    .add-eng {
      position: relative;
      right: 0px;
    }
    .add-eng {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 1170px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .dev-fee {
      font-size: 1.8rem;
    }
  }
`;

export default ManageUsers;
