import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SidebarNavItem from "./SidebarNavItem";
import { Burger } from "../../atoms/Hamburger";
import { useAuth } from "../../../../context/AuthState";
import {
  BsPieChartFill,
  BsPeopleFill,
  BsFillBellFill,
  BsPersonBoundingBox,
  BsGearFill,
  BsBarChartFill,
  BsFillLayersFill,
  BsFillGiftFill,
} from "react-icons/bs";
 import {FaGraduationCap} from "react-icons/fa";
 import {AiOutlineException} from "react-icons/ai";
 import {GiTeamUpgrade} from "react-icons/gi";



const DashboardSidebar = ({ navType, open, setOpen, handleShow }) => {

  const [activeClientNav, ] = useState(false);
  const [activeAdminEngNav, ] = useState(false);
  const [activeAdminSettings, ] = useState(false);
  const [activeOverviewNav, ] = useState(false);
  const [activeAdminSubscription, ] = useState(false);
  const [activeAdminEngReqNav, ] = useState(false);
  const [activeAdminSubReqNav, ] = useState(false);

  const [activeSubNav, ] = useState(true);
  const [activeClientEngNav, ] = useState(false);
  const [activeClientSettings,] = useState(false);
  
  const [activeClientEngReq,] = useState(false);
  const [activeClientSubReq] = useState(false);
  const [activeConfigReq] = useState(false);


  const { logout, permissionCheck } = useAuth();
  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles.split(",")


  return (
    <StyledDashboardSidebar>
      <div>
        <div className="sidebar-logo">
          <img src="/images/Decagon-logo.svg" alt="Decagon's logo" />
          <Burger open={open} setOpen={setOpen} />
        </div>
        <div className="sidebar-nav">
          <div className="sidebar-nav-title">
            <p>Menu</p>
          </div>
          {navType === "admin" &&
            permissionCheck(permArr, ["manageCoreConfiguration"]) && (
              <SidebarNavItem
                active={activeOverviewNav}
                to="/admin/overview"
                title="Overview"
                icon={<BsPieChartFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageClient",
              "manageAdminUser",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeClientNav}
                to="/admin/dashboard"
                title="Clients"
                icon={<BsPeopleFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageSubscription",
              "viewSubscription",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminSubscription}
                to="/admin/subscriptions"
                title="Subscriptions"
                icon={<BsFillBellFill className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageEngineer",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminEngNav}
                to="/admin/engineers"
                title="Engineers"
                icon={<BsPersonBoundingBox className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewEngineerRequest",
              "cancelEngineerRequest",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminEngReqNav}
                to="/admin/eng/requests"
                title="Engineer Request"
                icon={<GiTeamUpgrade className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewSubscriptionRequest",
              "cancelSubscriptionRequest",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminSubReqNav}
                to="/admin/sub/requests"
                title="Subscription Request"
                icon={<AiOutlineException className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            permissionCheck(permArr, ["viewSubscription"]) && (
              <SidebarNavItem
                active={activeSubNav}
                to="/client/dashboard"
                title="My Subscriptions"
                icon={<BsPeopleFill className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            permissionCheck(permArr, [
             
              "viewAssignment",
              "viewEngineerReport",
              "uploadEngineerReport",
              "addEngineerComment",
            ]) && (
              <SidebarNavItem
                active={activeClientEngNav}
                title="My Engineers"
                to="/client/engineers"
                icon={<BsPersonBoundingBox className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            permissionCheck(permArr, [
              "viewSubscriptionRequest",
              "subscriptionRequest",
              "cancelSubscriptionRequest",
            ]) && (
              <SidebarNavItem
                active={activeClientSubReq}
                title="Request Subscription"
                to="/client/subscription-request"
                icon={<AiOutlineException className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            permissionCheck(permArr, [
              "viewEngineerRequest",
              "engineerRequest",
              "cancelEngineerRequest",
            ]) && (
              <SidebarNavItem
                active={activeClientEngReq}
                title="Request Engineers"
                to="/client/engineers-request"
                icon={<GiTeamUpgrade className="sidebar-icon" />}
              />
            )}
          <div className="sidebar-nav-title others">
            <p className="">Others</p>
          </div>
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageAdminUser",
              "manageRole",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/settings"
                title="Settings"
                icon={<BsGearFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "managePackageSetup",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/packages"
                title="Packages"
                icon={<BsFillGiftFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageIndustrySetup",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/industries"
                title="Industries"
                icon={<BsBarChartFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageSquadSetup",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/squads"
                title="Squads"
                icon={<FaGraduationCap className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageTechstackSetup",
              "manageCoreConfiguration",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/techstacks"
                title="TechStacks"
                icon={<BsFillLayersFill className="sidebar-icon" />}
              />
            )}

          {navType === "client" &&
            permissionCheck(permArr, ["manageAdminUser"]) && (
              <SidebarNavItem
                active={activeClientSettings}
                title="Settings"
                to="/client/settings"
                icon={<BsGearFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, ["manageCoreConfiguration"]) && (
              <SidebarNavItem
                active={activeConfigReq}
                to="/admin/config"
                title="System Config"
                icon={<BsPeopleFill className="sidebar-icon" />}
                select={true}
                handleShow={handleShow}
              />
            )}
          <div className="logout-section" onClick={() => logout(navType)}>
            <span>
              <img
                src="/images/logout-icon.svg"
                className="logout-icon"
                alt="logout"
              />
            </span>
            <span className="green-text logout-text ">Logout</span>
          </div>
        </div>
      </div>
    </StyledDashboardSidebar>
  );
};

DashboardSidebar.propTypes = {
  navType: PropTypes.oneOf(["admin", "client"]).isRequired,
};

const StyledDashboardSidebar = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  padding: 2rem 2.4rem 0 2.4rem;

  .sidebar-nav {
    height: calc(100vh - 14rem);
    overflow-y: scroll;
    flex-direction: column;
    display: flex;
  }
  .sidebar-logo {
    margin: 0 auto 5.501rem;
    width: 15rem;
    height: 3.605rem;
    display: flex;
    align-items: center;
    .hamburger {
      margin-left: 15px;
    }
  }
  .open-icon {
    transform: scale(1);
    transition: ease all 0.5s;
    margin-left: 2rem;
    cursor: pointer;
    :hover {
      transform: scale(1.25);
    }
  }
  .open-others {
    margin-left: 0.5rem;
  }
  .close-others {
    display: none;
  }
  .sidebar-nav-title p {
    padding-left: 2.3rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #21334f;
    margin-bottom: 1.6rem;
  }
  .others {
    margin-top: 3.6rem;
    display: flex;
    flex-direction: row;
  }
  .logout-section {
    cursor: pointer;
    bottom: 12rem;
    margin-top: auto;
    padding: 0.5rem 0 0.5rem 2.3rem;
    transition: margin-left 0.47s;
  }
  .logout-section:hover {
    margin-left: 1rem;
  }
  .logout-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
    margin-top: -0.8rem;
  }
  .logout-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export default DashboardSidebar;
