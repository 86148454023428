import React from 'react';
import styled from "styled-components";
import {NavLink} from "react-router-dom";


const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #E5E5E5;
  background: rgba(33, 51, 79, 0.85);
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-150%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 1;


  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0D0C1D;
    color: white !important;
    
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`

export const DashboardMenu = ({ open, onClick }) => {
  return (
    <StyledMenu open={open} onClick={onClick}>
     
        <NavLink to="/admin/overview">Overview</NavLink>
        <NavLink to="/admin/dashboard">Clients</NavLink>
        <NavLink to="/admin/subscriptions">Subscriptions</NavLink>
        <NavLink to="/admin/engineers">Engineers</NavLink>

        <NavLink to="/admin/settings">Settings</NavLink>

      
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 3rem;
    height: 0.3rem;
    background: green;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
  @media only screen and (max-width: 1170px){

  }
`

export const Burger = ({ open, setOpen, offset }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)} offset={offset} className={'hamburger'}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
