import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import styled from "styled-components";
import MyButton from '../../landingPage/MyButton';
import FormInputComponent from './enterpriseFormInputs/FormInputComponent';
import FormTitleSection from './FormTitleSection';
import { toast } from "react-toastify";
import { apiGet, apiPut } from "../../../utils/apiHelpers";
import { FadeLoader, ClipLoader} from 'react-spinners';
import { formatErrors } from '../../../utils/helperFunctions';
import FormSelectComponent from './enterpriseFormInputs/FormSelectComponent';


const ConfigForm = ({ onClick, group }) => {

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);

    const [formDetails, setFormDetails] = useState([]);

    const [initialValues, setInitialValues] = useState({

    });

    useEffect(() => {
        fetchFormPayload();
        // eslint-disable-next-line
    }, []);

    // Fetch form Details
    const fetchFormPayload = async () => {
        try {
            setLoading(true)
            const res = await apiGet(
                `/admin/configuration/${group}`
            );
            let result = res.data.data;

            setFormDetails(result);
            setInitialValues(populateInitialValues(result));
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err);
        }
    };

    const populateInitialValues = (data) => {
        let obj = {};
        data.map((item) => {
            formik.values[item.key] = item.value;
            return obj[item.key] = item.value;
        })
        return obj
    }

    const onSubmit = (values, { setFieldError }) => {
        updateFormDetails(values, setFieldError);
    };

    //   create Engineer
    const updateFormDetails = async (values, setFieldError) => {

        const errorFunctions = {
            others: setFieldError
        }

        setLoad(true);
        //make call to the api
        try {
            let response = await apiPut(`/admin/configuration/${group}`, values, {}, true);
            if (response.error) {
                throw response.data.response.status === 500 ? new Error("") : response.data;
            }
            setSuccess(true);
            setLoad(false);

        } catch (err) {
            setLoad(false);
            console.log({ err });

            if (err.response.data.subErrors && err.response.data.subErrors.length !== 0) {
                formatErrors(err.response.data.subErrors, errorFunctions);
            }

            toast.error(
                err?.response?.data?.message ||
                "Not able to create package at this time"
            );
        }
    };


    const formik = useFormik({
        initialValues,
        onSubmit,
        // validationSchema
    })

    const closeForm = () => {
        formik.resetForm();
        onClick();
        setSuccess(false);
    }

    return (
        loading ? <FadeLoader color="white" style={{margin: "0 auto"}} size={50}/> :
        <StyledCreatePackageForm success={success}>
            <FormTitleSection title="Edit Config" onClick={closeForm} />
            <div className="form-section">
                <form onSubmit={formik.handleSubmit} className="client-form">
                    <p className="form-font form-title">Config Details</p>
                    {formDetails && formDetails.map((item, index) => {
                        return item.type === "TEXT" ? (<FormInputComponent label={item.name} value={formik.values[item.key]} name={item.key} type={item.type.toLowerCase()} onChange={formik.handleChange} onBlur={formik.handleBlur} description={item.description} key={item.key} />) : 
                        item.type === "SELECT" ? <FormSelectComponent label={item.name} value={formik.values[item.key]} name={item.key} type={item.type.toLowerCase()} onChange={formik.handleChange} onBlur={formik.handleBlur} description={item.description} key={item.key} options={item.metadata} /> : ""
                    })}
                    <MyButton className="form-btn add-client-btn" type={"submit"} full>
                        {" "}
                        {load ? (
                            <ClipLoader color={"#fff"} size={"30px"} />
                        ) : (
                            "Edit Configuration"
                        )}
                    </MyButton>
                </form>
                <div className="form-success">
                    <img
                        src="/images/sucess-icon.svg"
                        alt="green-tick"
                        className="sucess-icon"
                    />
                    <h2 className="form-font green-text">Success</h2>
                    <p className="form-font success-msg">
                        You successfully updated the configuration.
            </p>
                    <MyButton className="form-btn success-btn" onClick={closeForm}>
                        Okay, Go back to Previous Page
            </MyButton>
                </div>
            </div>
        </StyledCreatePackageForm>
    );
}

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

  .pck-input {
    display: inline-block;
    font-size: 1.6rem;
    background: transparent;
    -webkit-appearance: none;
    min-width: 32rem;
    border: none;
    height: 4.8rem;
    padding-bottom: 0rem;
    padding-left: 1rem;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
  }
  @media only screen and (max-width: 405px) {
    .pck-input {
      min-width: 100%;
    }
  }
`;

export default ConfigForm
