import axios from "axios";



const getAccessToken = async () => {
  const user = localStorage?.getItem("userType")
  const accessPayload = {
    accessToken: localStorage?.getItem("token"),
    refreshToken: localStorage?.getItem("refreshToken"),
    userName: JSON.parse(
      localStorage.getItem("client_details")
    ).email
  };
  try {
    const {
      data: {
        data: { token },
      },
    } = await apiPost(`/${user}/refreshToken`, accessPayload, {}, false);
    if (token) localStorage.setItem("token", token);
    return token;
  } catch (err) {
    console.log(err);
  }
};


  axios.interceptors.response.use(null, async (err) => {
    if (err.response.status === 401) {
      let originalReq = err.config;
      const token = await getAccessToken();
      if (token) {
        localStorage.setItem("token", token);
        originalReq.headers["Authorization"] = `Bearer ${token}`;
        return axios(originalReq);
      }
     
    }
    else {
      return Promise.reject(err);
    }
  });


export const apiGet = (path, conf = {}, auth = "admin") => {
  const config = {
    ...conf,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios.get(`${process.env.REACT_APP_BASE_URL}${path}`, config);
};

export const apiPost = (path, data, { headers, ...conf }, auth = true) => {
  const Authorization = auth && `Bearer ${localStorage.getItem("token")}`;
  const config = {
    ...conf,
    headers: {
      Authorization,
      ...(headers ? headers : {}),
    },
  };

  return axios.post(`${process.env.REACT_APP_BASE_URL}${path}`, data, config);
};

export const apiPut = (path, data, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.put(`${process.env.REACT_APP_BASE_URL}${path}`, data, config);
};

export const apiPatch = (path, data, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.patch(`${process.env.REACT_APP_BASE_URL}${path}`, data, config);
};

export const apiDelete = (path, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.delete(`${process.env.REACT_APP_BASE_URL}${path}`, config);
};

export const errorHandler = (payload) => {};
