import React, { useReducer } from "react";
import {
  CLEAR_ERRORS,
  LOADING,
  FETCH_SUB_REQ_SUCCESS,
  FETCH_ENG_REQ_SUCCESS, FETCH_CLIENT_SUCCESS,
  FETCH_NEW_CLIENT_SUCCESS,
  FETCH_TECH_STACK_SUCCESS,
  ASSIGNMENT_LISTING,
  FETCH_INDUSTRY_SUCCESS,
  FETCH_SQUAD_SUCCESS,
  FETCH_PACKAGE_SUCCESS,
  FETCH_USER_SUCCESS,
  ORG_SUB_SUCCESS,
  FETCH_CLIENT_ENG_SUCCESS,
  FETCH_UNASSIGNED,
  FETCH_STACK_SUCCESS,
  FETCH_ENGINEER_SUCCESS,
  FETCH_ASSIGNMENT_INFO,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_CLIENT_SUB_SUCCESS,
  FETCH_ADMIN_ENG_SUCCESS,
  FETCH_ADMIN_SUB_SUCCESS

} from "./types";
import { toast } from "react-toastify";
import { apiPost, apiGet } from "../utils/apiHelpers";
import AdminContext from "./AdminContext";
import AdminReducer from "./AdminReducer";

const AdminState = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    error: null,
    isVerified: false,
    success: false,
    dashboardData: {},
    assignment_info: {},
    engineersList: [],
    subReqList: [],
    engReqList: [],
    organisationsList: [],
    assignmentStatusList: [],
    stackList: [],
    page: 1,
  };

  const [state, dispatch] = useReducer(AdminReducer, initialState);

  let OrganizationDetails =
    JSON.parse(localStorage.getItem("client_details")) || {};
  let organisationId = OrganizationDetails.organisationId;

  

  //Fetch table data
  const fetchClients = async () => {
  
    try {
      const res = await apiGet(
        `/admin/organisations?page=${state.page}&size=50`,
        "admin"
      );
      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }

      dispatch({
        type: FETCH_CLIENT_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Error Fetching organization"
      );
    }

    // dispatch({ type: LOADING, loading: false });
  };

  //Fetch new table data
  const fetchNewClients = async () => {
   
    try {
      const res = await apiGet(
        `/admin/organisations?page=1&size=5`,
        "admin"
      );
      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }

      dispatch({
        type: FETCH_NEW_CLIENT_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Error Fetching organization"
      );
    }


  };

  //Fetch subscription table data
  const fetchSubscriptions = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/subscriptions", "admin");

      dispatch({
        type: FETCH_SUBSCRIPTION_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch engineers table data
  const fetchEngineers = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/admin/engineers", "admin");

      dispatch({
        type: FETCH_ENGINEER_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch user listing per organization
  const viewUsers = async (id) => {
    dispatch({ type: LOADING, loading: true });

    try {
      const res = await apiGet(`/organisations/${id}/users`);
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }
    dispatch({ type: LOADING, loading: false });
  };

  //Fetch subscription listing per organization
  const viewSubs = async (id) => {
    dispatch({ type: LOADING, loading: true });

    try {
      const res = await apiGet(`/organisations/${id}/subscriptions`);
      dispatch({
        type: ORG_SUB_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }
    dispatch({ type: LOADING, loading: false });
  };

  //Fetch assignment listing per engineer
  const fetchAssignmentListing = async (id) => {
    dispatch({ type: LOADING, loading: true });

    try {
      const res = await apiGet(`/admin/engineers/${id}/assignments`);
      dispatch({
        type: ASSIGNMENT_LISTING,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }
    dispatch({ type: LOADING, loading: false });
  };

  // Fetch Stack options
  const fetchStackOptions = async () => {
    try {
      const res = await apiGet(`/admin/techstacks`);

      dispatch({
        type: FETCH_STACK_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error(err);
    }
  };

  // Fetch client subscription
  const fetchClientSubscription = async () => {
    dispatch({ type: LOADING, loading: true });

    try {
      const res = await apiGet(
        `/organisations/${organisationId}/subscriptions`,
        {},
        "client"
      );

      dispatch({
        type: FETCH_CLIENT_SUB_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, loading: false });
  };

  // Fetch client Engineers
  const fetchClientEngineers = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet(
        `/organisations/${organisationId}/assignments`,
        {},
        "client"
      );
      dispatch({
        type: FETCH_CLIENT_ENG_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch list of assignment info
  const fetchAssignmentInfo = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/forms/assignment", {}, "client");

      dispatch({
        type: FETCH_ASSIGNMENT_INFO,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch list of assignment info
  const fetchUnassigned = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/admin/engineers/unassigned", {}, "admin");
      dispatch({
        type: FETCH_UNASSIGNED,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch packages table data
  const fetchPackages = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/admin/packages", "admin");

      dispatch({
        type: FETCH_PACKAGE_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch admin subscription requests
  const fetchAdminSubReq = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/subscriptions/requests", "admin");
      dispatch({
        type: FETCH_ADMIN_SUB_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch admin engineer requests
  const fetchAdminEngReq = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/assignments/requests", "admin");
      dispatch({
        type: FETCH_ADMIN_ENG_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch industry table data
  const fetchIndustry = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/admin/industries", "admin");

      dispatch({
        type: FETCH_INDUSTRY_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch squad table data
  const fetchSquads = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/admin/squads", "admin");

      dispatch({
        type: FETCH_SQUAD_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  //Fetch Tech Stack table data
  const fetchTechStackListing = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      const res = await apiGet("/admin/techstacks", "admin");

      dispatch({
        type: FETCH_TECH_STACK_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, loading: false });
  };

  // create subscription
  const createSubscription = async (orgId, formData) => {
    dispatch({ type: LOADING, loading: true });

    //make call to the api
    try {
      const res = await apiPost(
        `/organisations/${orgId}/subscriptions`,
        formData,
        {}
      );
      if (res.status < 400) {
        return true;
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "Not able to create subscription at this time"
      );
    }
    dispatch({ type: LOADING, loading: false });
  };

  // Engineer Assignment
  const assignEngineer = async (formData) => {
    dispatch({ type: LOADING, loading: true });
    try {
      await apiPost(`/assignments`, formData, {});
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, loading: false });
  };
  //fetch eng req

  const fetchEngReq = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      let response = await apiGet(`/assignments/requests`, {});
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_ENG_REQ_SUCCESS,
        payload: response.data.data.content || [],
      });
    } catch (err) {
      console.log({ err: err });
      toast.error(
        err?.response?.data?.message || "error feftching squad details"
      );
    }
    dispatch({ type: LOADING, loading: false });
  };

  //fetch sub request
  const fetchSubReq = async () => {
    dispatch({ type: LOADING, loading: true });
    try {
      let response = await apiGet(`/subscriptions/requests`, {});
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_SUB_REQ_SUCCESS,
        payload: response.data.data.content || [],
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "error feftching squad details"
      );
    }
    dispatch({ type: LOADING, loading: false });
  };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });
  const gf = (pageType, id) => {
    if (pageType === "clients") {
      fetchClients();
    } else if (pageType === "overview") {
      fetchNewClients();
    }else if (pageType === "subscriptions") {
      fetchSubscriptions();
    } else if (pageType === "engineers") {
      fetchEngineers();
    } else if (pageType === "client_subscriptions") {
      fetchClientSubscription();
    } else if (pageType === "client_engineers") {
      fetchClientEngineers();
    } else if (pageType === "users") {
      viewUsers(id);
    } else if (pageType === "sub") {
      viewSubs(id);
    } else if (pageType === "adminEngReq") {
      fetchAdminEngReq();
    } else if (pageType === "adminSubReq") {
      fetchAdminSubReq();
    } else if (pageType === "package") {
      fetchPackages();
    } else if (pageType === "squad") {
      fetchSquads();
    } else if (pageType === "industry") {
      fetchIndustry();
    } else if (pageType === "assignments") {
      fetchAssignmentListing(id);
    } else if (pageType === "techstack") {
      fetchTechStackListing();
    } else if (pageType === "clientSubReq") {
      fetchSubReq();
    } else if (pageType === "clientEngineersReq") {
      fetchEngReq();
    }
  };

  return (
    <AdminContext.Provider
      value={{
        client_token: state.client_token,
        assignment_info: state.assignment_info,
        engineersList: state.engineersList,
        subReqList: state.subReqList,
        engReqList: state.engReqList,
        assignmentStatusList: state.assignmentStatusList,
        organisationsList: state.organisationsList,
        loading: state.loading,
        page: state.page,
        fetchClients,
        fetchNewClients,
        fetchSubscriptions,
        fetchClientSubscription,
        fetchEngineers,
        fetchStackOptions,
        fetchClientEngineers,
        viewUsers,
        viewSubs,
        fetchPackages,
        fetchSquads,
        fetchIndustry,
        fetchAssignmentListing,
        fetchTechStackListing,
        fetchUnassigned,
        fetchEngReq,
        fetchSubReq,
        fetchAdminSubReq,
        fetchAdminEngReq,
        gf,
        dashboardData: state.dashboardData,
        clearErrors,
        assignEngineer,
        createSubscription,
        stackList: state.stackList,
        fetchAssignmentInfo,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => {
  const context = React.useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useAdmin must be used within a AuthProvider");
  }
  return context;
};

export default AdminState;
