import React, { useState } from "react";
import styled from "styled-components";
import ListingInterface from "../common/ListingInterface";
import { adminEngineerView } from "../common/listingData";
import Layout from "../common/Layout";

const AdminEngineerPage = ({ pageType }) => {
  const [activeModal, setActiveModal] = useState(false);

  const handleModal = () => setActiveModal(!activeModal);

  return (
    <Layout navType="admin">
      <StyledDashboardPage activeModal={activeModal}>
            <ListingInterface
              details={adminEngineerView}
              handleModal={handleModal}
              pageType={pageType}
            />
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div`
`;

export default AdminEngineerPage;
