import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiPost, apiGet, apiPut } from "../../../utils/apiHelpers";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import MyButton from "../../landingPage/MyButton";
import { toast } from "react-toastify";

const AddRole = ({
  onClick,
  fetchAdminRoles,
  isEditing,
  setIsEditing,
  updateId,
  pageType
}) => {
  const [initialValues,] = useState({
    name: "",
    description: "",
    permissions: [],
    roleType: `${pageType}`.toUpperCase()
  });


  const validationSchema = Yup.object({
    name: Yup.string().required("*Required"),
    description: Yup.string().required("*Required"),
    permissions: Yup.array().required("*Required"),
    roleType: Yup.string().required("*Required"),
  });


  const [success, setSuccess] = useState(false);
  const [loading,] = useState(false);
  const [permList, setPermList] = useState([]);
  const [permValue, setPermValue] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);


  const fetchSingleRole = async () => {
     let permArr = [];
    try {
      let response = await apiGet(`/roles/${updateId}`);
      let res = response.data.data;
 
      const fields = ["name", "description"];
      fields.forEach((field) =>
        formik.setFieldValue(field, res[field], false));
       formik.setFieldValue("roleType", `${pageType}`.toUpperCase(), false);
     res.permissions.forEach((perm) => perm.checked ? permArr.push(perm.id): null);
      setSelectedRole(permArr);
      formik.setFieldValue("permissions", selectedRole, false);
    } catch (err) {
      toast.error(err);
    }
  };


  useEffect(() => {
    if (isEditing) {
      fetchSingleRole();
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const new_token = localStorage.getItem("token");

  let config = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-type": "application/json; charset=UTF-8",
    },
  };

  const createRole = async (formData) => {
    try {
      if (!isEditing) {
        await apiPost(`/roles`, formData, config);
        setSuccess(true);
      }

      if (isEditing) {
        await apiPut(`/roles/${updateId}`, formData, config);
        setSuccess(true);
      }
    } catch (err) {
      toast.error("Not able to create roles at this time");
    }
  };
  useEffect(() => {
    fetchAdminRoles();
    // eslint-disable-next-line
  }, [success]);

  

  const fetchPermissions = async () => {
    try {
      let response = await apiGet(`/admin/permissions`);
      const permArr = [];
      const permVal = []
      response.data.data.map((item) => {
        permArr.push(item.name)
        permVal.push(item.id)
        return item
      });
      setPermList(permArr);
      setPermValue(permVal)
    } catch (err) {
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchPermissions();
  }, []);

  const onSubmit = (values) => {
    createRole({ ...values, roleType: `${pageType}`.toUpperCase() });
    if (success) {
       setIsEditing(false);
    }
   
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
    setIsEditing(false);
  };


  const handleCheck = (e) => {
    
    const { checked, value } = e.target;
    if (
      checked &&
      formik.values.permissions.findIndex((item) => item === value) === -1
    ) {
      formik.setFieldValue("permissions", [...selectedRole, value]);
      setSelectedRole([...selectedRole, value]);
    } else {
      let newSelect = selectedRole.filter((v) => v !== value);
      setSelectedRole(newSelect);
      formik.setFieldValue(
        "permissions",
        formik.values.permissions.filter((v) => v !== value)
      );
    }
  };
 
  return (
    <StyledRoleForm>
      <FormTitleSection title="Add New Roles" onClick={closeForm} />
      <div className="form-section">
        {!success ? (
          <form className="client-form" onSubmit={formik.handleSubmit}>
            <FormInputComponent
              label="Role"
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
              placeholder="Enter Name"
            />

            <FormInputComponent
              label="Description"
              type="text"
              name="description"
              placeholder="Enter Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : null
              }
            />

            <div className="mt-5">
              <p className="perm-head">Select Permissions</p>
              <div className="grid-container mt-4">
                {permList.map((item, index) => (
                  <label
                    htmlFor={"chk" + index}
                    key={index}
                    className="check-label"
                  >
                    <input
                      className="checkbox-area"
                      type="checkbox"
                      id={"chk" + index}
                      name="permissions"
                      value={permValue[index]}
                      onChange={handleCheck}
                      checked={selectedRole.find((x) => x === permValue[index])}
                    />
                    <span className="checkmark"></span>
                    <span className="item-span"> {item}</span>
                 
                  </label>
                ))}
              </div>
            </div>

            <MyButton
              className="form-btn add-client-btn mt-5"
              type="submit"
              full
            >
              {isEditing ? "Save Role" : " Add New Role"}
            </MyButton>
          </form>
        ) : null}

        {success ? (
          <div className="form-success card">
            <img
              src="/images/sucess-icon.svg"
              alt="green-tick"
              className="sucess-icon"
            />
            <h2 className="form-font green-text">Success</h2>
            <p className="form-font success-msg">
              You have successfully added an new Role.
            </p>
            <MyButton
              className="form-btn success-btn"
              loading={loading}
              onClick={closeForm}
            >
              View List of Roles
            </MyButton>
          </div>
        ) : null}
      </div>
    </StyledRoleForm>
  );
};
const StyledRoleForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);

  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: block;
  }
  .form-success {
    display: flex;
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
  }
  .permissions-div {
    display: flex;
  }
  .add-perms-btn {
    padding: 0.1rem 2rem;
    margin-top: 3.2rem;
    border: none;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .search-wrapper {
    padding: 1rem;
    font-size: 1.7rem;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
  }
  .searchBox::placeholder {
    color: rgb(104, 116, 135);
  }
  .perm-label {
    color: #21334f;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
  .checkbox-area {
    -webkit-appearance: button;
    margin-right: 1.5rem;
    display: none;
  }

  .check-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .checkmark {
    width: 25px;
    height: 25px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    background: #34a853
      url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png")
      center/1250% no-repeat;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .item-span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .perm-head {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;
export default AddRole;
