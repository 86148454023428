import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../../common/Layout";
import { Container } from "react-bootstrap";
import SettingsRender from '../../admin/SettingsView/SettingsRender';

const ClientSettingPage = () => {
  const [activeModal, ] = useState(false);

  return (
    <Layout navType="client" hasBackground>
      <StyledDashboardPage activeModal={activeModal}>
        <Container>
          <SettingsRender pageType="client" />
        </Container>
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div``;

export default ClientSettingPage;
