import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "./context/AuthState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLoader from "./components/enterprise/atoms/CustomLoader";
import ProtectedRoute from "./components/enterprise/local-routes/ProtectedRoutes";
import ErrorBoundary from "./components/enterprise/common/ErrorBoundary";
import SubscriptionPage from "./components/enterprise/pages/SubscriptionPage";
import ClientSubscriptionPage from "./components/enterprise/client/pages/ClientSubscriptionPage";
import SettingsPage from "./components/enterprise/pages/SettingsPage";
import ClientSettingPage from "./components/enterprise/client/pages/ClientSettingPage";
import AdminEngineerPage from "./components/enterprise/pages/AdminEngineerPage";
import AdminState from "./context/AdminState";
import ClientEngineerPage from "./components/enterprise/client/pages/ClientEngineerPage";
import UserListingPage from "./components/enterprise/pages/UserListingPage";
import SubListingPage from "./components/enterprise/pages/SubListingPage";
import AssignmentListing from "./components/enterprise/pages/AssignmentListing";
import PackageListing from "./components/enterprise/pages/PackageListing";
import SquadListing from "./components/enterprise/pages/SquadListing";
import IndustryListing from "./components/enterprise/pages/IndustryListing";
import TechStackListing from "./components/enterprise/pages/TechStackListing";
import SingleClientSubscription from "./components/enterprise/pages/SingleClientSubscription";
import AdminSubscriptionRequestPage from "./components/enterprise/pages/AdminSubscriptionRequestPage";
import AdminEngineerRequestPage from "./components/enterprise/pages/AdminEngineerRequestPage";
const Home = React.lazy(() => import("./components/landingPage/pages/Home"));
const CallForm = React.lazy(() =>
  import("./components/landingPage/form/CallForm")
);
const FormBody = React.lazy(() =>
  import("./components/landingPage/form/FormBody")
);
const OrganizationPage = React.lazy(() =>
  import("./components/enterprise/pages/OrganizationPage")
);
const SubscribeForm = React.lazy(() =>
  import("./components/landingPage/form/SubscribeForm")
);
const FormParentBody = React.lazy(() =>
  import("./components/enterprise/admin/FormParentBody")
);
const DashboardPage = React.lazy(() =>
  import("./components/enterprise/pages/DashboardPage")
);
const CreatePassword = React.lazy(() =>
  import("./components/enterprise/client/CreatePassword")
);
const Login = React.lazy(() => import("./components/enterprise/admin/Login"));
const OverviewPage = React.lazy(() =>
  import("./components/enterprise/pages/OverviewPage")
);
const SingleSubscription = React.lazy(() =>
  import("./components/enterprise/pages/SingleSubscription")
);
const SingleEngineerPage = React.lazy(() =>
  import("./components/enterprise/pages/SingleEngineerPage")
);
const SingleEngineerAdminPage = React.lazy(() =>
  import("./components/enterprise/pages/SingleEngineerAdminPage")
);
const ForgotPassword = React.lazy(() =>
  import("./components/enterprise/admin/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/enterprise/admin/ResetPassword")
);
const ClientSubRequestPage = React.lazy(() =>
  import("./components/enterprise/client/pages/ClientSubRequestPage")
);
const ClientEngRequestPage = React.lazy(() =>
  import("./components/enterprise/client/pages/ClientEngRequestPage")
);

function App() {
  const { isAuthenticated } = useAuth();
 
  return (
    <ErrorBoundary>
      <Suspense fallback={<CustomLoader />}>
        <Router basename={process.env.REACT_APP_FS_BASENAME || ""}>
          <AdminState>
            <>
              <ToastContainer style={{ fontSize: "16px" }} />
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/call">
                  <FormBody component={<CallForm />} />
                </Route>
                <Route exact path="/subscribe">
                  <FormBody component={<SubscribeForm />} />
                </Route>
                <Route exact path="/login">
                  <FormParentBody component={<Login type="client" />} />
                </Route>
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={[
                    "assignEngineer",
                    "manageClient",
                    "manageEngineer",
                    "manageOrganisationUser",
                    "viewAssignment",
                    "uploadEngineerReport",
                    "viewEngineerReport",
                    "addEngineerComment",
                  ]}
                  path="/admin/dashboard/:id"
                  component={() => <OrganizationPage />}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={["manageCoreConfiguration"]}
                  path="/admin/overview"
                  component={() => <OverviewPage />}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  path="/admin/dashboard"
                  permission={[
                    "manageClient",
                    "manageAdminUser",
                    "manageCoreConfiguration",
                  ]}
                  component={() => <DashboardPage pageType="clients" />}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={[
                    "manageSubscription",
                    "viewSubscription",
                    "manageCoreConfiguration",
                  ]}
                  path="/admin/subscriptions"
                  component={() => (
                    <SubscriptionPage pageType="subscriptions" />
                  )}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "client"}
                  permission={[
                    "uploadEngineerReport",
                    "uploadEngineerReport",
                    "viewEngineerReport",
                    "addEngineerComment",
                  ]}
                  path="/client/engineers/:id"
                  component={() => (
                    <SingleEngineerPage />
                  )}
                />

                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={[
                    "viewAssignment",
                    "viewEngineerReport",
                    "uploadEngineerReport",
                    "uploadEngineerReport",
                    "addEngineerComment",
                  ]}
                  path="/admin/engineers/assignments/:id"
                  component={() => (
                    <AssignmentListing pageType="assignments" />
                  )}
                />
                 
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={[
                    "viewAssignment",
                    "viewEngineerReport",
                    "uploadEngineerReport",
                    "uploadEngineerReport",
                    "addEngineerComment",
                  ]}
                  path="/admin/engineers/:id"
                  component={() => (
                     <SingleEngineerAdminPage />
                  )}
                />
     
                <ProtectedRoute
                    exact
                    role={isAuthenticated === "admin"}
                    permission={["manageClient", "manageAdminUser"]}
                    path="/admin/dashboard/users/:id"
                    component={() => (
                       <UserListingPage pageType="users" />
                    )}
                />
              
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  path="/admin/packages"
                  permission={["managePackageSetup", "manageCoreConfiguration"]}
                  component={() => (
                    <PackageListing pageType="package" />
                      )}
                />
                
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={["manageSquadSetup", "manageCoreConfiguration"]}
                  path="/admin/squads"
                  component={() => (<SquadListing pageType="squad" />
                  )}
                />

                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  path="/admin/industries"
                  permission={[
                    "manageIndustrySetup",
                    "manageCoreConfiguration",
                  ]}
                  component={() => (
                    <IndustryListing pageType="industry" />
                 )}
                />
               
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={[
                    "manageTechstackSetup",
                    "manageCoreConfiguration",
                  ]}
                  path="/admin/techstacks"
                  component={() => (
                     <TechStackListing pageType="techstack" />
                   )}
                />
              
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={["manageSubscription", "viewSubscription"]}
                  path="/admin/subscriptions/sublisting/:id"
                  component={() => (
                  <SubListingPage pageType="sub" />
                  )}
                />
             
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={["manageSubscription", "viewSubscription"]}
                  path="/admin/subscriptions/sublisting/:id/:subId"
                  component={() => (
                    <SingleSubscription
                      pageType="sub"
                      isAuthenticated={isAuthenticated}
                    />
                  )}
                />
             
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  path="/admin/subscriptions/:id"
                  permission={["manageSubscription", "viewSubscription"]}
                  component={() => (
                    <SingleSubscription
                      isAuthenticated={isAuthenticated}
                      pageType="admin"
                    />
                  )}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  path="/admin/engineers"
                  permission={["manageEngineer", "manageCoreConfiguration"]}
                  component={() => <AdminEngineerPage pageType="engineers" />}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  path="/admin/sub/requests"
                  permission={[
                    "viewSubscriptionRequest",
                    "cancelSubscriptionRequest",
                    "manageCoreConfiguration",
                  ]}
                  component={() => (
                    <AdminSubscriptionRequestPage pageType="adminSubReq" />
                  )}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  path="/admin/eng/requests"
                  permission={[
                    "viewEngineerRequest",
                    "cancelEngineerRequest",
                    "manageCoreConfiguration",
                  ]}
                  component={() => (
                    <AdminEngineerRequestPage pageType="adminEngReq" />
                  )}
                />
                <ProtectedRoute
                  exact
                  role={isAuthenticated === "admin"}
                  permission={[
                    "manageAdminUser",
                    "manageRole",
                    "manageCoreConfiguration",
                  ]}
                  path="/admin/settings"
                  component={() => <SettingsPage />}
                />
                <ProtectedRoute
                  exact
                  path="/client/dashboard"
                  role={isAuthenticated === "client"}
                  permission={["viewSubscription"]}
                  component={() => (
                    <ClientSubscriptionPage pageType="client_subscriptions" />
                  )}
                />
                <ProtectedRoute
                  exact
                  path="/client/dashboard/:id"
                  role={isAuthenticated === "client"}
                  permission={["viewSubscription"]}
                  component={() => (
                    <SingleClientSubscription
                      isAuthenticated={isAuthenticated}
                    />
                  )}
                />
                <ProtectedRoute
                  role={isAuthenticated === "client"}
                  exact
                  permission={["manageAdminUser"]}
                  path="/client/settings"
                  component={() => <ClientSettingPage />}
                />
                <ProtectedRoute
                  exact
                  path="/client/engineers"
                  permission={[
                    "viewAssignment",
                    "viewEngineerReport",
                    "uploadEngineerReport",
                    "addEngineerComment",
                  ]}
                  role={isAuthenticated === "client"}
                  component={() => (
                    <ClientEngineerPage pageType="client_engineers" />
                  )}
                />
                <ProtectedRoute
                  exact
                  path="/client/engineers-request"
                  role={isAuthenticated === "client"}
                  permission={[
                    "viewEngineerRequest",
                    "engineerRequest",
                    "cancelEngineerRequest",
                  ]}
                  component={() => (
                    <ClientEngRequestPage pageType="clientEngineersReq" />
                  )}
                />
                <ProtectedRoute
                  exact
                  path="/client/subscription-request"
                  permission={[
                    "viewSubscriptionRequest",
                    "subscriptionRequest",
                    "cancelSubscriptionRequest",
                    "managePackageSetup"
                  ]}
                  role={isAuthenticated === "client"}
                  component={() => (
                    <ClientSubRequestPage pageType="clientSubReq" />
                  )}
                />
               
                <Route exact path="/createpassword">
                  <FormParentBody component={<CreatePassword />} />
                </Route>
                <Route exact path="/admin/forgot-password">
                  <FormParentBody
                    component={<ForgotPassword pageType="admin" />}
                  />
                </Route>
                <Route exact path="/resetpassword/admin/:token">
                  <FormParentBody
                    component={<ResetPassword pageType="admin" />}
                  />
                </Route>
                <Route exact path="/client/forgot-password">
                  <FormParentBody
                    component={<ForgotPassword pageType="client" />}
                  />
                </Route>
                <Route exact path="/resetpassword/client/:token">
                  <FormParentBody
                    component={<ResetPassword pageType="client" />}
                  />
                </Route>
                <Route
                  path="*"
                  component={() => <p>THIS PAGE DOES NOT EXIST</p>}
                />
                {!localStorage.getItem("token" && <Redirect to='/' />)}
              </Switch>
            </>
          </AdminState>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
