import React, {useState} from 'react';
import styled from "styled-components";
import Layout from "../../common/Layout";
import ListingInterface from "../../common/ListingInterface";
import { clientEngineerView } from "../../common/listingData";



const ClientEngineerPage = ({ pageType }) => {
    const [activeModal, setActiveModal] = useState(false);
  
    const handleModal = () => setActiveModal(!activeModal);
  
    return (
      <Layout navType="client">
        <StyledEngineerPage activeModal={activeModal}>
              <ListingInterface
                details={clientEngineerView}
                handleModal={handleModal}
                pageType={pageType}
              />
         
        </StyledEngineerPage>
      </Layout>
    );
  };
  
  const StyledEngineerPage = styled.div`
  
  `;
  
  export default ClientEngineerPage;
  