import React from 'react'
import styled from "styled-components";

const FormModal = (props) => {
    return (
        <StyledFormModal>
            <div className="form-section">
                {props.children}
            </div>
        </StyledFormModal>
    )
}

const StyledFormModal = styled.div`
    background: rgba(33, 51, 79, 0.44);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    .form-section{
    }
    @media only screen and (max-width: 1200px) {
        background: rgba(33, 51, 79, 0.44);
        .grey-background{
            display: none;
        }
        .form-section{
            margin: 0 auto;
            max-width: 100%;
        }
    }
    @media only screen and (max-width: 405px) {
        .form-section{
            width: 100vw;
        }
    }

`;
export default FormModal
