export const organizationView = {
    placeholder: "search for clients",
    btnInfo: "Add new Clients",
    title: "Clients",
    headers: ["Company", "Contact Name", "Email Address", "Phone Number", "Subscription Plan", "Status", "Action"],
    options : [
        { value: "name", label: "company name" },
        { value: "status", label: "status" },
      ],
      tableDetails: ["name", "contactPerson", "email", "telephoneNumber", "subscriptionPlan", "status", "action"],
      path: "/admin/organisations",
      auth: "admin",

};

export const overviewClients = {
  
  headers: [
    "Company",
    "Contact Name",
    "Email Address",
    "Phone Number",
    "Subscription Plan",
    "Status",
    "Action",
  ],
  
  tableDetails: [
    "name",
    "contactPerson",
    "email",
    "telephoneNumber",
    "subscriptionPlan",
    "status",
    "action",
  ],
  path: "/admin/organisations",
  auth: "admin",
};



export const clientSubscriptionView = {
    placeholder: "search for subscriptions",
    btnInfo: "",
    title: "Subscriptions",
    headers: ["Package", "Number of Engineer", "Used Slots", "Available Credit", "Start Date", "Expiry Date", "Status", "Action"],

    options : [
        { value: "name", label: "company name" },
        { value: "status", label: "status" },
      ],
      tableDetails: ["packageName", "numEngineers", "used", "availableCredit", "startDate", "expireDate", "status", "action"],
      path: "/client/subscriptions",
      auth: "client"

};


export const clientEngineerView = {
  placeholder: "search for engineers",
  btnInfo: "",
  title: "Engineers",
  headers: ["Name", "Email address", "Phone Number", "Status", "Credit Allocation", "Action"],

  options : [
      { value: "name", label: "company name" },
      { value: "status", label: "status" },
    ],
    tableDetails: ["name", "email", "telephoneNo", "status", "creditAllocated", "action"],
    path: (id) => `/organisations/${id}/assignments`,
    auth: "client"

};

export const adminEngReq = {
  placeholder: "search for engineers",
  btnInfo: "",
  title: "Engineer Request",
  headers: ["Organisation", "Request Date", "Tech Stack", "Status", "Action"],

  options : [
      { value: "name", label: "company name" },
      { value: "status", label: "status" },
    ],
    tableDetails: ["organisation", "requestDate", "techStack", "status", "action"],
    path: `/assignments/requests`,
    auth: "admin"

};

export const adminSubReq = {
  placeholder: "search for subscriptions",
  btnInfo: "",
  title: "Subscription Request",
  headers: ["Organisation", "Package Name", "Request Date", "Status", "Action"],

  options : [
      { value: "name", label: "company name" },
      { value: "status", label: "status" },
    ],
    tableDetails: ["organisation", "packageName", "requestDate", "status", "action"],
    path: `/subscriptions/requests`,
    auth: "admin"

};

export const clientEngRequest = {
  placeholder: "search for engineers",
  btnInfo: "Request Engineers",
  title: "Engineers Request",
  headers: ["Organisation", "Tech Stack", "Request Date", "Status", "Action"],

  options: [
    { value: "name", label: "company name" },
    { value: "status", label: "status" },
  ],
  tableDetails: [
    "organisation",
    "techStack",
    "requestDate",
    "status",
    "action",
  ],
  path: `/assignments/requests`,
  auth: "client",
};

export const clientSubReqView = {
  placeholder: "search for subscriptions",
  btnInfo: "View Details",
  title: "Subscriptions Request",
  headers: [
    "Organisation",
    "Package",
    "Request Date",
    "Status",
    "Action",
  ],

  options: [
    { value: "name", label: "company name" },
    { value: "status", label: "status" },
  ],
  tableDetails: [
    "organisation",
    "packageName",
    "requestDate",
    "status",
    "action",
  ],
  path: "/subscriptions/requests",
  auth: "client",
};


export const adminSubscriptionView = {
    placeholder: "search for subscriptions",
    btnInfo: "Add Subscriptions",
    title: "Subscriptions",
    headers: ["Organisation", "Package", "Start Date", "Expiry Date", "Status", "Action"],
    options : [
        { value: "name", label: "company name" },
        { value: "status", label: "status" },
      ],
      tableDetails: ["organisation", "packageName", "startDate", "expireDate", "status", "action"],
      path: "/admin/subscriptions",
      auth: "admin"

};



export const adminEngineerView = {
  placeholder: "search for engineers",
  btnInfo: "Add Engineers",
  title: "Engineers",
  headers: ["Name", "Email Address", "Phone Number", "Vendor", "Status", "Credit Alloc.", "Action"],
  options : [
      { value: "name", label: "company name" },
      { value: "status", label: "status" },
    ],
    tableDetails: ["name", "email", "telephoneNo", "vendor", "status", "creditAllocated", "action"],
    path: () => `/admin/engineers`,
    auth: "admin"

};


export const userListing = {
  placeholder: "search for users",
  btnInfo: "Add Users",
  title: "Users",
  headers: ["Name", "Email", "Phone", "Date Added", "Role", "Status", "Action"],

  options : [
      { value: "name", label: "Name" },
      { value: "status", label: "Status" },
    ],
    tableDetails: ["name", "email", "phone", "dateAdded", "role", "status", "action"],
    auth: "admin"

};

export const subListing = {
  placeholder: "search for subscriptions",
  btnInfo: "Add Subscriptions",
  title: "Subscriptions",
  headers: ["Organisation Name", "Package Name", "Num of Engineers", "Used slots", "Available Credit", "Start Date", "Expiry Date", "Status", "Action"],

  options : [
      { value: "name", label: "Name" },
      { value: "status", label: "Status" },
    ],
    tableDetails: ["organisation", "packageName", "numEngineers", "used", "availableCredit", "startDate", "expireDate", "status", "action"],
    auth: "admin"

};


export const assignmentListing = {
  placeholder: "",
  btnInfo: "",
  title: "Assignment History",
  headers: ["Vendor", "Assignment Date", "Status Date", "End Date",  "Credit Allocation", "Status", "Action"],

  options : [
      { value: "name", label: "Name" },
      { value: "status", label: "Status" },
    ],
    tableDetails: ["vendor", "assignmentDate",  "statusDate", "endDate", "creditAllocated", "status", "action"],
    auth: "admin"

};

export const packageListing = {
  placeholder: "search for packages",
  btnInfo: "Add Packages",
  title: "Packages",
  headers: ["Name", "Amount", "Date Added", "Duration", "Num of Engineers",  "Action"],

  options : [
      { value: "name", label: "Name" },
      { value: "status", label: "Status" },
    ],
    tableDetails: ["name", "amount", "dateAdded", "duration", "numOfEngineers", "action"],
    auth: "admin",
    path: `/admin/packages`,

};


export const squadListing = {
  placeholder: "search for squad",
  btnInfo: "Add Squad",
  title: "Squads",
  headers: ["Name",  "Action"],

  options : [
      { value: "name", label: "Name" },
      { value: "status", label: "Status" },
    ],
    tableDetails: ["name", "action"],
    auth: "admin",
    path: `/admin/squads`,


};


export const industryListing = {
  placeholder: "search for industry",
  btnInfo: "Add Industry",
  title: "Industry",
  headers: ["Name", "Description", "Action"],

  options : [
      { value: "name", label: "Name" },
      { value: "status", label: "Status" },
    ],
    tableDetails: ["name", "description", "action"],
    auth: "admin",
    path: `/admin/industries`,


};


export const techStackListing = {
  placeholder: "search for tech stack",
  btnInfo: "Add TechStack",
  title: "TechStack",
  headers: ["Id", "Name", "Action"],

  options : [
      { value: "name", label: "Name" },
      { value: "status", label: "Status" },
    ],
    tableDetails: ["id", "name", "action"],
    auth: "admin",
    path: `/admin/stacks`,


};



export const employeeNumber = [
  {"value": "", "label": "Choose your Option"},
  {"value": "1-10", "label": "1-10"},
  {"value": "11-50", "label": "11-50"},
  {"value": "50-100", "label": "50-100"},
  {"value": "Above 100", "label": "Above 100"}
]

export const developerNumber = [
  {"value": "", "label": "Choose your Option"},
  {"value": "1-10", "label": "1-10"},
  {"value": "11-50", "label": "11-50"},
  {"value": "50-100", "label": "50-100"},
  {"value": "Above 100", "label": "Above 100"}
]



