import React, {useState} from "react";
import Layout from "../common/Layout";
import styled from "styled-components";
import ListingInterface from "../common/ListingInterface";
import {assignmentListing} from "../common/listingData";



const AssignmentListing = ({pageType}) => {

    const [activeModal, setActiveModal] = useState(false);

  const handleModal = () => setActiveModal(!activeModal);
    return (
        <Layout navType="admin">
            <StyledDashboardPage activeModal={activeModal}>
                <ListingInterface
                    details={assignmentListing}
                    handleModal={handleModal}
                    pageType={pageType}
                />

            </StyledDashboardPage>
        </Layout>
    );
};

const StyledDashboardPage = styled.div`

`;

export default AssignmentListing;
