import {
  LOADING,
  FETCH_CLIENT_SUCCESS,
  FETCH_NEW_CLIENT_SUCCESS,
  FETCH_ENGINEER_SUCCESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_CLIENT_SUB_SUCCESS,
  FETCH_ASSIGNMENT_INFO,
  FETCH_STACK_SUCCESS,
  FETCH_CLIENT_ENG_SUCCESS,
  FETCH_USER_SUCCESS,
  ORG_SUB_SUCCESS,
  FETCH_PACKAGE_SUCCESS,
  FETCH_INDUSTRY_SUCCESS,
  FETCH_SQUAD_SUCCESS,
  ASSIGNMENT_LISTING,
  FETCH_TECH_STACK_SUCCESS,
  FETCH_UNASSIGNED,
  FETCH_SUB_REQ_SUCCESS,
  FETCH_ENG_REQ_SUCCESS,
  FETCH_ADMIN_ENG_SUCCESS,
  FETCH_ADMIN_SUB_SUCCESS
} from "./types";

export default function AdminReducer (state, action) {

  switch (action.type) {
    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, clients: action.payload },
      };
    case FETCH_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, overview: action.payload },
      };
    case FETCH_ADMIN_ENG_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, adminEngReq: action.payload },
      };
    case FETCH_ADMIN_SUB_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, adminSubReq: action.payload },
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, users: action.payload },
      };
    case ORG_SUB_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, sub: action.payload },
      };
    case FETCH_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, package: action.payload },
      };
    case FETCH_INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, industry: action.payload },
      };
    case ASSIGNMENT_LISTING:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, assignments: action.payload },
      };
    case FETCH_SQUAD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, squad: action.payload },
      };
    case FETCH_TECH_STACK_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, techstack: action.payload },
      };

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_ENGINEER_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, engineers: action.payload },
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          ...state.dashboardData,
          subscriptions: action.payload,
        },
      };
    case FETCH_CLIENT_SUB_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          ...state.dashboardData,
          client_subscriptions: action.payload,
        },
      };
    case FETCH_CLIENT_ENG_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          ...state.dashboardData,
          client_engineers: action.payload,
        },
      };
    case FETCH_STACK_SUCCESS:
      return {
        ...state,
        loading: false,
        stackList: action.payload,
      };
    case FETCH_ASSIGNMENT_INFO:
      return {
        ...state,
        loading: false,
        assignmentStatusList: action.payload.assignmentStatusList,
        organisationsList: action.payload.organisationsList,
      };
    case FETCH_UNASSIGNED:
      return {
        ...state,
        engineersList: action.payload,
      };
    case FETCH_SUB_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, clientSubReq: action.payload },
      };
    case FETCH_ENG_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          ...state.dashboardData,
          clientEngineersReq: action.payload,
        },
      };

    default:
      return state;
  }
};
