import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import FormImgComponent from "./enterpriseFormInputs/FormImgComponent";
import SelectedStack from "./SelectedStack";
import { apiPost, apiGet } from "../../../utils/apiHelpers";
import { setSelect } from "../../../utils/helperFunctions";
import { toast } from "react-toastify";
import { useAdmin } from "../../../context/AdminState";
import { ClipLoader } from "react-spinners";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  telephoneNo: "",
  technicalSkills: "",
  majorTechStacks: "",
  squad: "",
};

const phoneRegExp = /^\d*(\+\d+)?$/;

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("*Required"),
  firstName: Yup.string().required("*Required"),
  lastName: Yup.string().required("*Required"),
  telephoneNo: Yup.string()
    .min(9, "*Phone number is not valid")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  technicalSkills: Yup.string(),
  majorTechStacks: Yup.number("select an option").required("*Required"),
  squad: Yup.string("select an option").required("*Required"),
});

const AddEngForm = ({ onClick, reload }) => {


  const { stackList } = useAdmin();

  const [success, setSuccess] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stack, setStack] = useState("");
  const [resumeDoc, setResumeDoc] = useState({});
  const [profileImg, setProfileImg] = useState({});
  const [loading, setLoading] = useState(false);
  const [stackResult, setStackResult] = useState([]);
  const [stacks, setStacks] = useState({});
  const [squadOptions, setSquadOptions] = useState([]);

  const [resumeDocError, setResumeDocError] = useState("");
  const [profileImgError, setProfileImgError] = useState("");
  const [selectedOptionsError, setSelectedOptionsError] = useState("");

  const docType = ["pdf", "docx", "doc"];

  const pictureType = ["jpg", "jpeg", "gif", "png"];



  useEffect(() => {
    setResumeDocError("");
  }, [resumeDoc]);

  useEffect(() => {
    setProfileImgError("");
  }, [profileImg]);

  useEffect(() => {
    setSelectedOptionsError("");
  }, [selectedOptions]);

  useEffect(() => {
    setStack(selectedOptions[selectedOptions.length - 1]);
  }, [selectedOptions]);


  useEffect(() => {
    let stckOpts = setSelect(stackList, "Stack");
    setStackResult(stckOpts);
    fetchSquadOptions();
  }, [stackList]);


  useEffect(() => {
    let result = getStacksFromstackResult(stackResult);
    setStacks(result);
  }, [stackResult]);


  function getStacksFromstackResult(stackResult) {
    let obj = {};

    for (let i = 0; i < stackResult.length; i++) {
      let current = stackResult[i];

      obj[current.value] = current.label;
    }
    return obj;
  }


  const handleStack = (event, majorTechStacks) => {
    if (stack !== "" && majorTechStacks !== event.target.value && !selectedOptions.includes(event.target.value)) {
      setSelectedOptions([...selectedOptions, event.target.value]);
    }
  };


  const deleteStack = (index) => {
    if (index > -1) {
      setSelectedOptions(selectedOptions.filter((value, i) => i !== index));
    }
  };


  // Fetch squad options
  const fetchSquadOptions = async () => {
    try {
      const res = await apiGet(`/admin/squads`);
      let result = res.data.data.content;

      let sqdOpts = setSelect(result, "Squad");

      setSquadOptions(sqdOpts);
    } catch (err) {
      toast.error(err);
    }
  };

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onSubmit = (values) => {

    values.otherTechStacks = selectedOptions;
    let newArr = [];
    newArr.push(values.majorTechStacks);
    values.majorTechStacks = newArr;

    if (!resumeDoc.name || !selectedOptions[0]) {
      if (!resumeDoc.name) {
        setResumeDocError("*Required");
      }
      if (!selectedOptions[0]) {
        setSelectedOptionsError("*Required");
      }
      return;
    }

    let formData = new FormData();
    formData.append("resume_upload", resumeDoc);
    if (profileImg) {
      formData.append("profile_upload", profileImg);
    }
    formData.append(
      "engineer_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );

    createEngineer(formData);
  };

  //create Engineer
  const createEngineer = async (formData) => {
    setLoading(true);

    //make call to the api
    try {
      let response = await apiPost(`/admin/engineers`, formData, multipartConfig);
      if (response.error) {
        throw response.data.response.status === 500 ? new Error("") : response.data;
      }
      setSuccess(true);
      setLoading(false);
      reload();

    } catch (err) {
      console.log({err})
      setLoading(false);
      toast.error(
        err?.response?.data?.message ||
        "Not able to create engineer at this time"
      );
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setResumeDoc(() => ({}));
    setSuccess(false);
  };


  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title="Add New Engineers" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <FormImgComponent
            pictureType={pictureType}
            label="Upload Image"
            type="file"
            error={profileImgError}
            selectedFile={profileImg}
            setSelectedFile={setProfileImg}
            acceptTypes={`jpg, jpeg, gif, png. Size Limit 5mb`}

          />
          <FormInputComponent
            label="Email address"
            type="email"
            name="email"
            placeholder="Enter email address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
          <FormInputComponent
            label="First Name"
            type="text"
            name="firstName"
            placeholder="Enter first name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />
          <FormInputComponent
            label="Last Name"
            type="text"
            name="lastName"
            placeholder="Enter last name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          />
          <FormInputComponent
            label="Phone number"
            type="tel"
            name="telephoneNo"
            placeholder="Enter phone number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telephoneNo}
            error={
              formik.touched.telephoneNo && formik.errors.telephoneNo
                ? formik.errors.telephoneNo
                : null
            }
          />
          <FormSelectComponent
            label="Major Stack"
            name="majorTechStacks"
            options={stackResult}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.majorTechStacks}
            error={
              formik.touched.majorTechStacks && formik.errors.majorTechStacks
                ? formik.errors.majorTechStacks
                : null
            }
          />

          <FormSelectComponent
            label="Other stack (select technology)"
            name="stack"
            options={stackResult}
            onChange={(e) => handleStack(e, formik.values.majorTechStacks)}
            value={stack}
            error={selectedOptionsError}
          />
          <div className="stack-section">
            {selectedOptions &&
              selectedOptions.map((option, index) => {
                return (
                  <SelectedStack
                    key={index}
                    name={stacks[option]}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteStack(index);
                    }}
                  />
                );
              })}
          </div>
          <FormSelectComponent
            label="Squad"
            name="squad"
            options={squadOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.squad}
            error={
              formik.touched.squad && formik.errors.squad
                ? formik.errors.squad
                : null
            }
          />

          <FormInputComponent
            label="Technical Skills"
            type="text"
            name="technicalSkills"
            placeholder="Enter tech skills"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.technicalSkills}
            error={
              formik.touched.technicalSkills && formik.errors.technicalSkills
                ? formik.errors.technicalSkills
                : null
            }
          />

          <FormUploadComponent
            label="Upload Resume"
            error={resumeDocError}
            selectedFile={resumeDoc}
            setSelectedFile={setResumeDoc}
            docType={docType}
            acceptTypes={`pdf, docx, doc. Size Limit 5mb`}

          />

          <MyButton className="form-btn add-client-btn" type="submit" full>
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
                "Add New Engineer"
              )}
          </MyButton>
        </form>
        <div className="form-success card p-3">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully added an Engineer.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go to view Engineer's list
          </MyButton>
        </div>
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default AddEngForm;
